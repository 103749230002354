import { useMemo, useState } from "react";
import { QuoteEvidence, StatementEvidence } from "../../../model/tracing";
import { AppTranscriptFragment } from "../../../model/transcript";
import { mapStatementEvidenceToTranscript } from "./tracing-utils";
import TranscriptBubble from "./TranscriptBubble";

const EvidenceView: React.FC<{
  evidence: StatementEvidence[];
  hideSoloStatement?: boolean;
  onQuoteEvidenceSelected?: (quote: QuoteEvidence) => void;
  transcript: AppTranscriptFragment[];
  adviserChannel: number;
  className?: string;
}> = (props) => {
  const { evidence, onQuoteEvidenceSelected, transcript, adviserChannel, className } = props;

  const [selectedStatement, setSelectedStatement] = useState<number | undefined>(undefined);

  // cache the mapping of quotes to transcript fragments
  // which is used so we can display a transcript bubble with the time and speaker info
  const transcriptMap: AppTranscriptFragment[][] = useMemo(
    () => mapStatementEvidenceToTranscript(evidence, transcript),
    [evidence, transcript],
  );

  const handleSelectionChange = (statement: number, quote: number) => {
    setSelectedStatement(statement);
    onQuoteEvidenceSelected?.(evidence[statement].quotes[quote]);
  };

  return (
    <div className={className}>
      {evidence.map((value, i) => (
        <div key={i}>
          <div
            key={`${i}`}
            className={`${
              selectedStatement === i ? "" : "opacity-60"
            } text-content-base cursor-pointer hover:opacity-80 w-fit`}
            onClick={() => handleSelectionChange(i, 0)}
          >
            {value.statement}
          </div>
          {value.quotes.map((q, j) => {
            const tx = transcriptMap[i][j];
            return (
              <div key={j} className={`${selectedStatement === i ? "" : "opacity-80"}`}>
                <TranscriptBubble
                  fragment={{
                    dur: tx.dur,
                    spk: tx.spk,
                    text: q.quote, // only the quoted text
                    ts: tx.ts,
                  }}
                  speakerRole={tx.spk === adviserChannel ? "adviser" : "client"}
                  isAccent={selectedStatement === i}
                  onClick={() => handleSelectionChange(i, j)}
                />
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default EvidenceView;
