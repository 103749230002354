import { useAuth0 } from "@auth0/auth0-react";
import { ImportedCallIndexListItem } from "@aveni-gptutils/api/services/import";
import { faSignOut, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortDirection,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useState } from "react";
import { Link } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { ErrorAlert } from "../components/alerts";
import { useListCalls } from "../hooks";

dayjs.extend(duration);

const columnHelper = createColumnHelper<ImportedCallIndexListItem>();

const columns = [
  columnHelper.accessor("id", {
    header: "Call ID",
    enableSorting: true,
    cell: (ctx) => (
      <Link className="hover:underline text-primary" to={ctx.getValue()}>
        {ctx.getValue()}
      </Link>
    ),
  }),
  columnHelper.accessor("adviserName", {
    header: "Adviser Name",
    enableSorting: true,
  }),
  columnHelper.accessor("clientName", {
    header: "Client Name",
    enableSorting: true,
  }),
  columnHelper.accessor((row) => Date.parse(row.timestamp), {
    header: "Timestamp",
    enableSorting: true,
    cell: (ctx) => new Date(ctx.getValue()).toLocaleString(),
  }),
  columnHelper.accessor("callType", {
    header: "Call Type",
    enableSorting: true,
  }),
  columnHelper.accessor("brand", {
    header: "Brand",
    enableSorting: true,
  }),
  columnHelper.accessor("duration", {
    header: "Duration",
    enableSorting: true,
    cell: (ctx) => dayjs.duration(ctx.getValue(), "seconds").format("HH:mm:ss"),
  }),
];

const SortWidget = (props: { sort: SortDirection | false }) => {
  switch (props.sort) {
    case "asc":
      return <FontAwesomeIcon className="pl-2" icon={faSortUp} />;
    case "desc":
      return <FontAwesomeIcon className="pl-2" icon={faSortDown} />;
    default:
      return null;
  }
};

const CallsPage = () => {
  const { logout, user } = useAuth0();
  const { data: calls, isLoading, error } = useListCalls();
  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable({
    data: calls ?? [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,

    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const onLogout = () => {
    logout({
      returnTo: window.location.origin,
    });
  };

  return (
    <div className="m-6">
      <div className="flex items-center space-x-4 mb-6">
        <h1 className="grow text-3xl font-light">
          Calls
          {isLoading && (
            <span className="ml-4">
              <PulseLoader color="gray" size="10px" />
            </span>
          )}
        </h1>
        <div className="tooltip tooltip-left tooltip-info" data-tip={`Signed in as ${user?.name}`}>
          <button className="btn btn-ghost btn-sm" onClick={onLogout}>
            <span className="hidden md:inline mr-2">Sign out</span>
            <FontAwesomeIcon icon={faSignOut} />
          </button>
        </div>
      </div>
      {error && <ErrorAlert message={error.message} />}
      {calls && (
        <table className="table w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        <SortWidget sort={header.column.getIsSorted()} />
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className="hover">
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default CallsPage;
