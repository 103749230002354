import { QuoteEvidence, StatementEvidence } from "../../../model/tracing";
import { AppTranscriptFragment } from "../../../model/transcript";

/**
 * Returns transcript fragments corresponding to evidence quotes.
 *
 * @param evidence evidence quotes
 * @param transcript transcript fragments
 * @returns list of transcript fragments, one per quote
 */
export function mapQuoteEvidenceToTranscript(
  evidence: QuoteEvidence[],
  transcript: AppTranscriptFragment[],
): AppTranscriptFragment[] {
  return evidence.map((qe) => {
    // scan the transcript slice for a matching quote
    // (avoiding the slice call here to skip the array copy)
    const { start, end } = qe.range;
    for (let i = start; i <= end; i++) {
      const tx = transcript[i];
      if (tx.text.includes(qe.quote)) return tx;
    }

    // reasonably noisy fallback for something that SHOULDN'T happen
    // unless something's gone wrong upstream or the transcript and analysis are out of sync
    console.error("QuoteEvidence didn't match transcript", {
      quoteEvidence: qe,
      transcript,
    });

    return transcript[start];
  });
}

/**
 * Returns transcript fragments corresponding to evidence statements and quotes.
 *
 * @param evidence evidence statements, each with a list of quotes
 * @param transcript transcript fragments
 * @returns array of lists of transcript fragments, one list per evidence statement
 */
export function mapStatementEvidenceToTranscript(
  evidence: StatementEvidence[],
  transcript: AppTranscriptFragment[],
): AppTranscriptFragment[][] {
  return evidence.map((se) => mapQuoteEvidenceToTranscript(se.quotes, transcript));
}
