import React, { useEffect, useRef } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

const TranscriptAudioPlayer: React.FC<{
  src: string;
  time: number;
  onTimeUpdate: (time: number) => void;
  className?: string;
}> = (props) => {
  const { src, time, onTimeUpdate, className } = props;

  const audioPlayerRef = useRef<AudioPlayer>(null);

  useEffect(() => {
    if (audioPlayerRef.current) {
      const audioPlayer = audioPlayerRef.current;
      if (audioPlayer.audio.current) {
        const audio = audioPlayer.audio.current;
        audio.currentTime = time;
      }
    }
  }, [time]);

  return (
    <AudioPlayer
      ref={audioPlayerRef}
      src={src}
      layout="stacked-reverse"
      className={`shadow-none p-0 ${className || ""}`}
      onListen={(e) => onTimeUpdate((e.target as HTMLAudioElement).currentTime)}
      onSeeked={(e) => onTimeUpdate((e.target as HTMLAudioElement).currentTime)}
    />
  );
};

export default TranscriptAudioPlayer;
