const CRMDisplayIdSuffix: React.FC<{ id: string }> = ({ id }) => {
  // nbsp helps ensure double-click selects only the id not the preceding text
  return (
    <>
      &nbsp;
      <span
        className="font-light before:content-['#'] ml-1 invisible group-hover:visible"
        onDoubleClick={() => navigator.clipboard.writeText(id)}
      >
        {id}
      </span>
    </>
  );
};

export default CRMDisplayIdSuffix;
