import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import { claimCheck } from "../auth";
import UnauthorizedPage from "../pages/UnauthorizedPage";

export type ProtectedRouteProps = {
  page: React.FC;
};

export const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
  // using the claimCheck function on withAuthenticationRequired tends to cause an infinite loop
  // but this is another way to do it that does work by splitting authentication and claims checking into two stages
  const ClaimCheck: React.FC = () => {
    const { user } = useAuth0();
    if (claimCheck(user)) {
      return <props.page />;
    } else {
      return <UnauthorizedPage />;
    }
  };

  const Page = withAuthenticationRequired(ClaimCheck);
  return <Page />;
};
