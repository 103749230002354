import "material-symbols";

import React, { ReactNode, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { CRMField, CRMPresentation, CRMTableValue } from "../../model/crm";
import { QuoteEvidence, StatementEvidence, TableEvidence } from "../../model/tracing";
import EvidenceView from "./components/EvidenceView";
import TranscriptSidebarContext from "./components/TranscriptSidebarContext";
import crmJson from "./data/crm.json";
import DemoMeetingContainer from "./DemoMeetingContainer";
import DemoMeetingContext from "./DemoMeetingContext";

const SetHighlightContext = React.createContext<(q: QuoteEvidence | undefined) => void>(() => {});

const DemoMeetingCRMView: React.FC = () => {
  const crm: CRMPresentation = crmJson as CRMPresentation;

  const { setWorkflowItemChecked } = useContext(DemoMeetingContext);
  const navigate = useNavigate();

  const [activeSection, setActiveSection] = useState("Personal Details");
  const [isSimulatingUpdate, setSimulatingUpdate] = useState<boolean>(false);

  const headings: Record<string, string> = {
    personal: "Personal Details",
    dependants: "Dependants",
    reasons: "Reasons for Seeking Advice",
    aspirations: "Lifestyle / Aspirations",
    assets: "Assets",
    liabilities: "Liabilities",
    income: "Income",
    expenditure: "Expenditure",
    estatePlanning: "Estate Planning",
    retirement: "Retirement Planning",
    attitudeRisk: "Attitude to Risk",
  };

  const handleScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    const container = e.currentTarget;
    const containerTop = container.offsetTop;
    const scrollTop = container.scrollTop;

    const sections = container.querySelectorAll("section");
    for (const section of sections) {
      const top = section.offsetTop;
      const pos = top - containerTop - scrollTop;
      if (pos < 8) {
        setActiveSection(section.id);
      } else {
        break;
      }
    }
  };

  const handleUpdateClick = () => {
    setSimulatingUpdate(true);
    setTimeout(() => {
      setSimulatingUpdate(false);
      setWorkflowItemChecked("crm", true);
      navigate("..", {
        relative: "path",
      });
    }, 2000);
  };

  const { setHighlightQuote } = useContext(TranscriptSidebarContext);

  return (
    <SetHighlightContext.Provider value={setHighlightQuote}>
      <DemoMeetingContainer
        navView="Update CRM"
        sidebar={<NavigationSidebar headings={headings} activeSection={activeSection} />}
      >
        <div className="max-w-screen-xl flex flex-col space-y-4 pt-4 border-1 h-screen flex-grow overflow-y-auto">
          <div className="flex items-center px-4 w-full space-x-16">
            <h2 className="text-lg md:text-xl lg:text-2xl font-light flex-grow">Update CRM</h2>
            <div className="flex space-x-2 items-center">
              <PulseLoader color="hsl(var(--n))" size="10px" className={isSimulatingUpdate ? "visible" : "invisible"} />
              <button disabled={isSimulatingUpdate} className="btn btn-primary btn-sm" onClick={handleUpdateClick}>
                Apply Update
              </button>
            </div>
          </div>

          <div className="p-4 pt-0 overflow-y-auto" onScroll={handleScroll}>
            <div className="bg-base-100 shadow-md flex flex-col px-4">
              {crm.sections.map((section, i) => (
                <section key={i} className="flex flex-col space-y-2 pb-4 pt-4" id={section.label}>
                  <h3 className="text-xl border-b pb-2 text-base-content font-light">{section.label}</h3>
                  {section.fields.map((field, j) => (
                    <CRMFieldControl key={j} crmField={field} />
                  ))}
                </section>
              ))}
            </div>
          </div>
        </div>
      </DemoMeetingContainer>
    </SetHighlightContext.Provider>
  );
};

const NavigationSidebar: React.FC<{ headings: Record<string, string>; activeSection: string }> = (props) => {
  const { headings, activeSection } = props;
  return (
    <div className="flex flex-col m-4 mt-20 space-y-16">
      <ul className="leading-loose">
        {Object.keys(headings).map((key, i) => (
          <li key={i}>
            <a
              href={`#${headings[key]}`}
              className={`link link-hover ${activeSection === headings[key] ? "opacity-100" : "opacity-75"}`}
            >
              {headings[key]}
            </a>
          </li>
        ))}
      </ul>
      <div className="font-light">
        <p>For CRM auto-fill:</p>
        <button className="link" data-tip="Fills your CRM from your browser">
          Install Browser Extension
        </button>
      </div>
    </div>
  );
};

const CRMFieldControl: React.FC<{
  crmField: CRMField;
}> = (props) => {
  const { crmField } = props;
  const crmFieldType = crmField.value?.type;
  switch (crmFieldType) {
    case "number":
      return <CRMFieldTextControl crmField={crmField} />;
    case "table":
      return <CRMTableControl crmField={crmField} />;
    case "text":
      return <CRMFieldTextControl crmField={crmField} />;
    case "text-area":
      return <CRMFieldTextControl crmField={crmField} maxWidthClass="max-w-prose" />;
    default:
      return (
        <div className="form-control w-full max-w-xs ">
          <label className="label">
            <span className="label-text">{crmField.label}</span>
          </label>

          <span className="ml-4 font-light italic text-sm text-base-content">n/a</span>
        </div>
      );
  }
};

const CRMFieldTextControl: React.FC<{
  crmField: CRMField;
  maxWidthClass?: string;
}> = (props) => {
  const { transcript, adviserChannel } = useContext(DemoMeetingContext);

  const { crmField, maxWidthClass } = props;
  const evidence = crmField.evidence as StatementEvidence[];
  const [tooltip, setTooltip] = useState("Copy to Clipboard");
  let setHighlightQuote = useContext(SetHighlightContext);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTooltip("Copy to Clipboard");
    }, 2000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [tooltip]);

  let text = "n/a";

  switch (crmField.value?.type) {
    case "number":
      text = crmField.value.data.toString();
      break;
    case "text":
      text = crmField.value.data;
      break;
    case "text-area":
      text = crmField.value.data;
      break;
    default:
      text = "n/a";
  }

  return (
    <div className="w-full">
      <Collapsible
        title={crmField.label ? crmField.label : ""}
        description={crmField.description ? crmField.description : ""}
        evidence={crmField.evidence ? true : false}
      >
        <div className="pb-2">
          {crmField.evidence ? (
            <div className="bg-base-100 rounded-box max-w-prose border max-h-96 p-4 overflow-y-auto">
              <EvidenceView
                hideSoloStatement={crmField.value?.type !== "text-area"}
                evidence={evidence}
                onQuoteEvidenceSelected={setHighlightQuote}
                transcript={transcript}
                adviserChannel={adviserChannel}
              />
            </div>
          ) : (
            <div className="border max-h-96 overflow-y-scroll relative max-w-prose">
              <div className="card-body py-0 px-4">
                <h4>No evidence found</h4>
              </div>
            </div>
          )}
        </div>
      </Collapsible>
      {!crmField.value ? (
        <div>
          <span className="ml-2 italic text-sm">n/a</span>
        </div>
      ) : (
        <div
          className={`flex items-center bg-base-200 hover:bg-base-300 rounded-btn group transition ease-out tooltip active:scale-btn-active tooltip-right cursor-pointer ${
            maxWidthClass ?? "max-w-xs"
          }`}
          data-tip={tooltip}
          onClick={() => {
            setTooltip("Copied!");
            navigator.clipboard.writeText(text);
          }}
        >
          <div className="w-full flex-grow p-2 text-left whitespace-pre-line">{text}</div>
          <span className="text-base-content opacity-20 group-hover:opacity-70 material-symbols-rounded font-light mx-1 p-1 cursor-pointer transition ease-out">
            content_copy
          </span>
        </div>
      )}
    </div>
  );
};

const CRMTableControl: React.FC<{ crmField: CRMField }> = (props) => {
  const { crmField } = props;
  const { transcript, adviserChannel } = useContext(DemoMeetingContext);
  const [isOpen, setIsOpen] = useState("");
  const [name, setName] = useState("");

  const value = crmField.value as CRMTableValue;
  const evidence = crmField.evidence as unknown as TableEvidence;
  let setHighlightQuote = useContext(SetHighlightContext);
  const handleToggle = (tableRow: string, tableCol: string) => {
    if (`${tableRow}+${tableCol}` === isOpen) {
      setIsOpen("");
    } else {
      setIsOpen(`${tableRow}+${tableCol}`);
    }
    setName(tableRow);
  };

  if (!value) return <></>;
  return (
    <div className="overflow-x-auto max-w-fit">
      <table className="table table-compact">
        <thead>
          <tr>
            {value.columns.map((columns, i) => (
              <th key={i}>{columns.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {!value.rows ? (
            <tr>
              <td className="col-span-3 italic">n/a</td>
            </tr>
          ) : (
            value.rows.map((row, i) => (
              <tr key={i}>
                {row.data.map((data, j) => {
                  return (
                    <td key={j}>
                      <div className="flex">
                        {data}
                        {j === 0 ? (
                          <button key={j} onClick={() => handleToggle(row.data[0], j.toString())}>
                            <span className="rounded-full material-symbols-rounded hover:bg-base-200 align-middle">
                              {isOpen === `${row.data[0]}+${j}` ? "expand_less" : "expand_more"}
                            </span>
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))
          )}
        </tbody>
      </table>
      {isOpen && (
        <div className="">
          <div className="pb-2">
            {evidence ? (
              <div className="bg-base-100 rounded-box max-w-prose border max-h-96 p-4 overflow-y-auto">
                <EvidenceView
                  hideSoloStatement={crmField.value?.type !== "text-area"}
                  evidence={evidence[name] ? evidence[name] : []}
                  onQuoteEvidenceSelected={setHighlightQuote}
                  transcript={transcript}
                  adviserChannel={adviserChannel}
                />
              </div>
            ) : (
              <div className="border max-h-96 overflow-y-scroll relative max-w-prose">
                <div className="card-body py-0 px-4">
                  <h4>No evidence found</h4>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const Collapsible: React.FC<{ title: string; children: ReactNode; description: string; evidence: boolean }> = (
  props,
) => {
  const { title, children, description, evidence } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  if (!evidence)
    return (
      <div className="px-1 min-h-0 py-2 text-left">
        <div className="label-text leading-tight">
          {title}
          <div className="max-w-prose">
            {!!description && <span className="label-text leading-tight opacity-70">{description}</span>}
          </div>
        </div>
      </div>
    );

  return (
    <div>
      <div className="px-1 min-h-0 py-2 text-left">
        <div className="tooltip tooltip-right" data-tip="Expand Evidence">
          <button onClick={handleToggle}>
            <div className="label-text leading-tight">
              {title}{" "}
              <span className="rounded-full material-symbols-rounded hover:bg-base-200 align-middle">
                {isOpen ? "expand_less" : "expand_more"}
              </span>
            </div>
          </button>
        </div>
        <div className="max-w-prose">
          {!!description && <span className="label-text leading-tight opacity-70">{description}</span>}
        </div>
      </div>
      {isOpen && <div className="">{children}</div>}
    </div>
  );
};

export default DemoMeetingCRMView;
