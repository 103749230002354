import { useAuth0 } from "@auth0/auth0-react";
import type { AppRouter } from "@aveni-gptutils/api/routers";
import { createTRPCReact, httpBatchLink } from "@trpc/react-query";
import { useState } from "react";
import config from "./config";

export const trpc = createTRPCReact<AppRouter>();

export const useTrpcClient = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url: `${config.api.url}/trpc`,
          async headers() {
            const accessToken = await getAccessTokenSilently();
            return {
              Authorization: `Bearer ${accessToken}`,
            };
          },
        }),
      ],
    }),
  );

  return trpcClient;
};
