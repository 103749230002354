import { CalendarEvent } from "../../../model/calendar";

const events: CalendarEvent[] = [
  {
    id: "7d401446-0967-44ee-a6ba-b75136971858",
    title: "Standup",
    invite: {
      platform: "meet",
      url: "https://meet.google.com/abc123",
    },
    startDateTime: "2023-02-14T08:30:00.000Z",
    endDateTime: "2023-02-14T09:00:00.000Z",
  },
  {
    id: "a0ea60f3-9472-4a05-b4a2-6142fd4ab094",
    title: "Fact Find - Will Smith",
    startDateTime: "2023-02-14T09:00:00.000Z",
    endDateTime: "2023-02-14T10:30:00.000Z",
    crmMeeting: {
      subject: "ACME Financial Planning - Full Holistic Review",
      type: "Call",
      url: "https://example.com/meeting/12345678",
    },
    crmWorkflow: {
      name: "Holistic Advice",
      stage: "Review Meeting",
      url: "https://example.com/workflow/abc123",
      value: "£1,700,000",
    },
    crmAccount: {
      name: "William Smith",
      title: "Mr",
      url: "https://example.com/account/xxxyyyzzz",
    },
    invite: {
      platform: "teams",
      url: "https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZWFkZDNlYmYtZWRhMy00N2RmLTg1ODgtNTY1NzY0YmY4ZWIx%40thread.v2/0?context=%7b%22Tid%22%3a%220e077d4f-9ae4-4abe-96fa-ee71df3c87be%22%2c%22Oid%22%3a%22e8e20bd4-bd00-4b60-a24d-d7babc976c87%22%7d",
    },
    recordingAnalysisType: "fs-fact-find",
    recordingAnalysis: {
      percentComplete: 100,
      transcribed: true,
      status: "Analysed",
    },
    isExternal: true,
    attendees: [
      {
        name: "You",
        role: "organizer",
        email: "you@advice.example.com",
        jobTitle: "Adviser",
      },
      {
        name: "Will Smith",
        role: "required",
        email: "willsmith@example.net",
      },
    ],
    infoItems: [
      {
        source: "crm",
        title: "Lead",
        content: `Lead via Acme Banking affiliate    
        Will is 44 - Senior Lab tech with two properties and significant inheritance.  
        Needs an updated fact find after 5 years with ACME.`,
      },
    ],
  },
  {
    id: "803c8a16-30c5-49e4-bff7-e8c58d50cbb3",
    title: "Presentation - Tim Apple (VIP)",
    startDateTime: "2023-02-14T14:00:00.000Z",
    endDateTime: "2023-02-14T16:30:00.000Z",
    crmMeeting: {
      subject: "Investment Proposal Walkthrough",
      type: "Teams Call",
      url: "https://example.com/meeting/12345678",
    },
    crmWorkflow: {
      name: "Holistic Advice",
      stage: "Presentation",
      value: "£50,000,000",
      url: "https://example.com/workflow/abc123",
    },
    crmAccount: {
      name: "Tim Apple",
      title: "Dr",
      url: "https://example.com/account/xxxyyyzzz",
    },
    invite: {
      platform: "teams",
      url: "https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZWFkZDNlYmYtZWRhMy00N2RmLTg1ODgtNTY1NzY0YmY4ZWIx%40thread.v2/0?context=%7b%22Tid%22%3a%220e077d4f-9ae4-4abe-96fa-ee71df3c87be%22%2c%22Oid%22%3a%22e8e20bd4-bd00-4b60-a24d-d7babc976c87%22%7d",
    },
    isExternal: true,
    attendees: [
      {
        name: "You",
        role: "organizer",
        email: "you@advice.example.com",
        jobTitle: "Adviser",
      },
      {
        name: "Dr Apple",
        role: "required",
        email: "ta@example.net",
      },
    ],
    infoItems: [
      {
        source: "crm",
        title: "Outline Proposal",
        content: `
- £50m investment
- High Net Worth Strategic Portfolio
- 1.5% initial fee (£750,000)`,
      },
    ],
  },
];

export default events;
