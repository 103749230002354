import React, { useCallback, useEffect, useState } from "react";
import { QuoteEvidence } from "../../../model/tracing";
import { AppTranscriptFragment } from "../../../model/transcript";
import Transcript from "./Transcript";
import TranscriptAudioPlayer from "./TranscriptAudioPlayer";
import TranscriptSearch, { AppTranscriptSearchHit } from "./TranscriptSearch";

const MAX_SEARCH_HITS = 25;
const ADVISER_CHANNEL = 0;

export type TranscriptSidebarProps = {
  transcript: AppTranscriptFragment[];
  audioSource: string;
  highlightQuote?: QuoteEvidence;
  onClose?: () => void;
  onTranscriptIndexChange?: (index: number) => void;
  onAudioTimeChange?: (time: number) => void;
  onHighlightQuoteClear?: () => void;
};

const TranscriptSidebar: React.FC<TranscriptSidebarProps> = (props) => {
  const {
    transcript,
    audioSource,
    highlightQuote,
    onClose,
    onTranscriptIndexChange,
    onAudioTimeChange,
    onHighlightQuoteClear,
  } = props;

  const [highlightSearchHit, setHighlightSearchHit] = useState<AppTranscriptSearchHit | undefined>();
  const [transcriptIndex, setTranscriptIndex] = useState<number>(0);
  const [audioTime, setAudioTime] = useState<number>(0);

  const handleSearchHitClick = useCallback(
    (hit: AppTranscriptSearchHit) => {
      setHighlightSearchHit(hit);
      onHighlightQuoteClear?.();
    },
    [onHighlightQuoteClear],
  );

  const handleTranscriptIndexChange = useCallback(
    (index: number) => {
      setTranscriptIndex(index);
      onTranscriptIndexChange?.(index);
    },
    [onTranscriptIndexChange],
  );

  const handleAudioTimeChange = useCallback(
    (time: number) => {
      setAudioTime(time);
      onAudioTimeChange?.(time);
    },
    [onAudioTimeChange],
  );

  useEffect(() => {
    if (highlightQuote) {
      handleTranscriptIndexChange(highlightQuote.range.start);
      handleAudioTimeChange(transcript[highlightQuote.range.start].ts);
    }
  }, [handleAudioTimeChange, handleTranscriptIndexChange, highlightQuote, transcript]);

  return (
    <div className="flex flex-col h-screen overflow-none bg-base-100 ">
      <div className="flex flex-row items-center">
        <div className="flex-grow flex flex-col text-base-content px-2 py-1 sm:px-4 sm:py-2 1080p:py-4">
          <h2 className="text-lg sm:text-xl 1080p:text-2xl font-medium">Playback</h2>
          <h2 className="text-sm sm:text-base 1080p:text-lg font-light">and transcript</h2>
        </div>
        <div className="flex-none px-2 py-1 sm:py-2 1080p:py-4">
          <button
            className="btn btn-ghost btn-circle material-symbols-rounded text-2xl sm:text-4xl leading-none text-base-content font-light text-opacity-75"
            onClick={() => onClose?.()}
          >
            close
          </button>
        </div>
      </div>
      <TranscriptSearch
        className="flex-grow px-4"
        transcript={transcript}
        adviserChannel={ADVISER_CHANNEL}
        maxSearchHits={MAX_SEARCH_HITS}
        onSearchHit={(hit) => {
          handleSearchHitClick(hit);
          handleTranscriptIndexChange(hit.index);
          handleAudioTimeChange(transcript[hit.index].ts);
        }}
        onSearchReset={() => setHighlightSearchHit(undefined)}
      />
      <Transcript
        className="overflow-y-scroll w-full flex-grow mt-2 pl-2 mb-2"
        transcript={transcript}
        adviserChannel={ADVISER_CHANNEL}
        onSeek={handleAudioTimeChange}
        audioIndex={transcriptIndex}
        highlightQuote={highlightQuote}
        highlightSearchHit={highlightSearchHit}
      />
      <TranscriptAudioPlayer
        src={audioSource}
        time={audioTime}
        className="flex-none w-full pt-2 pb-4 pl-4 pr-6 bg-base-100"
        onTimeUpdate={(time) => {
          const index = findTranscriptIndexByTime(transcript, time);
          handleTranscriptIndexChange(index);
        }}
      />
    </div>
  );
};

export default TranscriptSidebar;

function findTranscriptIndexByTime(transcript: AppTranscriptFragment[], time: number) {
  // binary-search transcript for the closest time
  let lo = 0;
  let hi = transcript.length - 1;
  while (lo < hi) {
    const mid = Math.floor((lo + hi) / 2);
    const f = transcript[mid];
    if (f.ts < time) {
      lo = mid + 1;
    } else {
      hi = mid;
    }
  }

  const f = transcript[lo];
  if (f.ts > time) {
    lo--;
  }

  return lo;
}
