import { JSONContent } from "@tiptap/core";
import React from "react";
import { AppTranscriptFragment } from "../../model/transcript.js";
import { WorkflowItem } from "../../model/workflow.js";

// Some of this would also be available via the TRPC context.
// I guess we could resolve a parent record here and pass it down.

export type DemoMeetingContextProps = {
  title: string;
  subtitle: string;
  transcript: AppTranscriptFragment[];
  adviserChannel: number;
  briefingNotes: JSONContent;
  setBriefingNotes: (briefingNotes: JSONContent) => void;
  workflowItems: WorkflowItem[];
  setWorkflowItemChecked: (id: string, checked: boolean) => void;
  setWorkflowItemTaskChecked: (id: string, taskIndex: number, checked: boolean) => void;
};

const DemoMeetingContext = React.createContext<DemoMeetingContextProps>({
  title: "",
  subtitle: "",
  transcript: [],
  adviserChannel: 0,
  workflowItems: [],
  briefingNotes: { type: "doc", content: [] },
  setBriefingNotes: () => {},
  setWorkflowItemChecked: () => {},
  setWorkflowItemTaskChecked: () => {},
});

export default DemoMeetingContext;
