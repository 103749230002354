import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import lunr from "lunr";
import { useMemo, useRef, useState } from "react";
import { AppTranscriptFragment } from "../../../model/transcript";
import TranscriptBubble from "./TranscriptBubble";

export interface AppTranscriptSearchHit {
  index: number;
  marks: { start: number; length: number }[];
}

const TranscriptSearch: React.FC<{
  transcript: AppTranscriptFragment[];
  adviserChannel: number;
  maxSearchHits: number;
  onSearchHit: (searchHit: AppTranscriptSearchHit) => void;
  onSearchReset: () => void;
  className?: string;
}> = (props) => {
  const { transcript, adviserChannel, onSearchHit, onSearchReset, maxSearchHits: maxSearchResults, className } = props;

  const searchButtonRef = useRef<HTMLButtonElement>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchHits, setSearchHits] = useState<AppTranscriptSearchHit[] | undefined>();
  const idx = useMemo(() => createTranscriptSearchIndex(transcript), [transcript]);

  return (
    <div className={`flex items-stretch ${className || ""}`}>
      <input
        type="text"
        placeholder="Search…"
        className="input input-bordered focus:outline-none input-sm flex-grow rounded-r-none"
        value={searchTerm}
        onChange={(e) => {
          const term = e.target.value;
          setSearchTerm(term);
          setSearchHits(undefined); // wipe the hits so we don't see a flash of old results on focus - bit of a hack
          if (term.length === 0) onSearchReset(); // keep the old term unless the field is fully cleared
        }}
        onKeyDown={({ key }) => {
          const searchButton = searchButtonRef.current;
          if (key === "Enter" && searchButton) {
            searchButton.focus();
            searchButton.click();
          }
        }}
      />
      <div className="dropdown dropdown-bottom dropdown-end">
        <button
          ref={searchButtonRef}
          className="btn btn-square btn-sm rounded-l-none"
          disabled={searchTerm.length < 3}
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              e.currentTarget.blur();
              e.stopPropagation();
            }
          }}
          onClick={() => {
            const result = idx.search(searchTerm);
            const hits = result.slice(0, Math.min(result.length, maxSearchResults)).map((r) => {
              const index = parseInt(r.ref);
              const metadata = r.matchData.metadata as Record<string, { text: { position: number[][] } }>;
              const marks = Object.keys(metadata)
                .map((k) => metadata[k].text.position)
                .flatMap((p) => p.map(([start, length]) => ({ start, length })));

              return {
                index,
                marks,
              };
            });

            // sort so we still have the top-scoring results
            // but they are ordered by transcript
            hits.sort((a, b) => a.index - b.index);

            setSearchHits(hits);
            onSearchReset();
          }}
        >
          <FontAwesomeIcon icon={faSearch} />
        </button>
        {searchHits !== undefined && searchHits.length === 0 && (
          <div className="dropdown-content p-2 bg-base-100 w-96 text-sm font-light rounded-md shadow cursor-pointer">
            No search results
          </div>
        )}
        {searchHits !== undefined && searchHits.length > 0 && (
          <div
            tabIndex={0}
            className="dropdown-content p-2 bg-base-100 w-96 max-h-96 lg:max-h-[28rem] xl:max-h-[32rem] 2xl:max-h-[40rem] overflow-auto text-sm font-light flex flex-col rounded-md shadow"
          >
            {searchHits.map((hit) => {
              const fragment = transcript[hit.index];
              return (
                <TranscriptBubble
                  key={hit.index}
                  fragment={fragment}
                  speakerRole={fragment.spk === adviserChannel ? "adviser" : "client"}
                  onClick={() => onSearchHit(hit)}
                  marks={hit.marks}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default TranscriptSearch;

function createTranscriptSearchIndex(transcript: AppTranscriptFragment[]): lunr.Index {
  return lunr(function () {
    this.ref("id");
    this.field("text");
    this.metadataWhitelist = ["position"];

    for (let i = 0; i < transcript.length; i++) {
      const f = transcript[i];
      this.add({
        id: i,
        text: f.text,
      });
    }
  });
}
