import { BubbleMenu, Editor } from "@tiptap/react";
import { useState } from "react";
import { EVIDENCE_TRACE_MARK } from "../../../editor/aveni-evidence-trace";
import { EvidenceTrace, QuoteEvidence, StatementEvidence } from "../../../model/tracing";
import { AppTranscriptFragment } from "../../../model/transcript";
import EvidenceView from "./EvidenceView";

const EvidenceEditorBubbleMenu: React.FC<{
  editor: Editor;
  onSelectedEvidenceChange?: (evidence: StatementEvidence[] | undefined) => void;
  onQuoteEvidenceSelected?: (quote: QuoteEvidence | undefined) => void;
  transcript: AppTranscriptFragment[];
  adviserChannel: number;
  className?: string;
}> = (props) => {
  const { editor, onSelectedEvidenceChange, onQuoteEvidenceSelected, transcript, adviserChannel, className } = props;

  // unusual situation where the evidence is sourced from the editor selection rather than being passed in
  const [evidence, setEvidence] = useState<StatementEvidence[] | undefined>(undefined);

  const handleSelectedEvidenceChange = (evidence: StatementEvidence[] | undefined) => {
    setEvidence(evidence);
    onSelectedEvidenceChange?.(evidence);
    onQuoteEvidenceSelected?.(undefined);
  };

  return (
    <BubbleMenu
      editor={editor}
      className={className}
      tippyOptions={{
        duration: 500,
        placement: "top",
        popperOptions: {
          placement: "top",
          modifiers: [
            {
              name: "preventOverflow",
              options: {
                rootBoundary: "document",
              },
            },
          ],
        },

        onShow: () => {
          if (editor.isActive(EVIDENCE_TRACE_MARK)) {
            const trace = editor.getAttributes(EVIDENCE_TRACE_MARK) as EvidenceTrace;
            handleSelectedEvidenceChange(trace.evidence);
          } else {
            handleSelectedEvidenceChange(undefined);
          }
        },
        onHidden: () => {
          handleSelectedEvidenceChange(undefined);
        },
      }}
    >
      {evidence && (
        <div className="bg-base-100 rounded-box shadow-xl w-[36rem] xl:w-[40rem] border h-96 p-4 overflow-y-auto">
          <EvidenceView
            evidence={evidence}
            transcript={transcript}
            adviserChannel={adviserChannel}
            onQuoteEvidenceSelected={onQuoteEvidenceSelected}
          />
        </div>
      )}
    </BubbleMenu>
  );
};

export default EvidenceEditorBubbleMenu;
