import { Extension } from "@tiptap/core";

export const DumpJson = Extension.create({
  addKeyboardShortcuts() {
    return {
      "Mod-j": (e) => {
        console.log(JSON.stringify(e.editor.getJSON(), null, 2));
        return false;
      },
    };
  },
});
