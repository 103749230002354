import { ImportedCall, TranscriptFragment } from "@aveni-gptutils/api/services/import";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import * as R from "remeda";
import { ErrorAlert } from "../components/alerts";
import { useGetCall } from "../hooks";
import { TranscriptPrefs, useTranscriptPrefs } from "../prefs";

dayjs.extend(duration);

const CallPage = () => {
  let { id } = useParams();

  const { data: call, isLoading, error } = useGetCall(id!);
  const [prefs, setPrefs] = useTranscriptPrefs();

  return (
    <div className="m-6">
      <div className="flex items-center space-x-4 mb-6">
        <h1 className="grow text-3xl font-light">
          Call <span className="font-extralight">{id}</span>
          {isLoading && (
            <span className="ml-4">
              <PulseLoader color="gray" size="10px" />
            </span>
          )}
        </h1>
        <Link className="btn btn-ghost btn-sm" to="..">
          Back to calls
        </Link>
      </div>
      {error && <ErrorAlert message={error.message} />}
      {call && (
        <>
          <div className="flex">
            <CallMetadata call={call} />
            <div className="ml-12 flex-grow">
              <TranscriptOptions prefs={prefs} setPrefs={setPrefs} />
            </div>
          </div>
          <TextTranscript transcript={call.transcript} prefs={prefs} />
        </>
      )}
    </div>
  );
};

const CallMetadata: React.FC<{ call: ImportedCall }> = ({ call }) => {
  return (
    <section className="mb-6 w-fit">
      <legend className="font-medium mb-3">Call Metadata</legend>
      <table className="table table-compact">
        <tbody>
          <tr>
            <th>Timestamp</th>
            <td>{dayjs(call.timestamp).format("YYYY-MM-DD HH:mm:ss")}</td>
          </tr>
          <tr>
            <th>Duration</th>
            <td>{dayjs.duration(call.duration, "seconds").format("HH:mm:ss")}</td>
          </tr>
          <tr>
            <th>Type</th>
            <td>{call.callType || <i>unknown</i>}</td>
          </tr>
          <tr>
            <th>Brand</th>
            <td>{call.brand || <i>unknown</i>}</td>
          </tr>
          <tr>
            <th>Adviser</th>
            <td>{call.adviserName || <i>unknown</i>}</td>
          </tr>
          <tr>
            <th>Client</th>
            <td>{call.clientName || <i>unknown</i>}</td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};

const TranscriptOptions: React.FC<{ prefs: TranscriptPrefs; setPrefs: (prefs: TranscriptPrefs) => void }> = (props) => {
  const { prefs: savedPrefs, setPrefs: updateSavedPrefs } = props;

  const [preamble, setPreamble] = useState("preamble" in savedPrefs ? savedPrefs.preamble : "");
  const [lineNumbers, setLineNumbers] = useState("lineNumbers" in savedPrefs ? savedPrefs.lineNumbers : "num");
  const [type, setType] = useState(savedPrefs.type ?? "text");
  const [maxPause, setMaxPause] = useState(savedPrefs.maxPause);

  const applyPrefs = () => {
    const prefs = {
      type,
      preamble: preamble?.trim(),
      lineNumbers,
      maxPause,
    };

    updateSavedPrefs(prefs);
  };

  const hasChanged = () => {
    return !R.equals(savedPrefs, { type, preamble, lineNumbers, maxPause });
  };

  return (
    // section containing a form laid out vertically, with a set of radio buttons for TranscriptPrefs.lineNumbers and a text area for TranscriptPrefs.preamble
    <section className="mb-6">
      <fieldset className="flex flex-col form-control">
        <legend className="font-medium">Transcript Options</legend>
        <div className="flex space-x-6">
          <label className="flex space-x-4 label">
            <span>Text:</span>
            <input
              className="input input-bordered input-sm"
              type="checkbox"
              checked={type === "text"}
              onChange={(e) => setType(e.target.checked ? "text" : "json")}
            />
          </label>
          <label className="flex space-x-4 label">
            <span>Max pause:</span>
            <input
              className="input input-bordered input-sm"
              type="number"
              value={maxPause}
              min={0}
              max={60}
              onChange={(e) => setMaxPause(parseFloat(e.target.value))}
            />
          </label>
        </div>
        <div className="flex space-x-2">
          <span className="label">Line Numbering: </span>
          <label className="flex label">
            <input
              type="radio"
              className="radio"
              disabled={type === "json"}
              checked={lineNumbers === "none" || !lineNumbers}
              onChange={(e) => setLineNumbers("none")}
            />
            <span className="pl-2">None</span>
          </label>
          <label className="flex label">
            <input
              type="radio"
              className="radio"
              disabled={type === "json"}
              checked={lineNumbers === "num" || lineNumbers === "line num"}
              onChange={(e) => setLineNumbers("num")}
            />
            <span className="pl-2">&quot;NN&quot;</span>
          </label>
        </div>
        <label className="flex flex-col space-y-4">
          <span className="label pb-0">Preamble:</span>
          <textarea
            className="textarea bg-neutral text-neutral-content font-mono"
            disabled={type === "json"}
            value={preamble}
            onChange={(e) => setPreamble(e.target.value)}
          />
        </label>
        <button className="btn btn-primary btn-sm w-fit mt-6" onClick={applyPrefs} disabled={!hasChanged()}>
          Apply Changes
        </button>
      </fieldset>
    </section>
  );
};

const TextTranscript: React.FC<{ transcript: TranscriptFragment[]; prefs: TranscriptPrefs }> = ({
  transcript,
  prefs,
}) => {
  const text = useMemo(() => {
    const merged = prefs.maxPause ? mergeTranscriptFragments(transcript, prefs.maxPause) : transcript;
    if (prefs.type === "text") {
      return formatTextTranscript(merged, prefs.preamble ?? "", prefs.lineNumbers ?? "num");
    } else {
      return JSON.stringify(merged, null, 2);
    }
  }, [transcript, prefs]);

  return (
    <section className="mb-6">
      <div className="prose min-w-full relative">
        <pre>{text}</pre>
        <button
          className="absolute top-0 right-0 m-2 btn btn-info btn-sm rounded-md text-white"
          title="Copy to Clipboard"
          onClick={() => {
            navigator.clipboard.writeText(text);
          }}
        >
          <FontAwesomeIcon icon={faCopy} className="px-2" />
        </button>
      </div>
    </section>
  );
};

function formatTextTranscript(
  transcript: TranscriptFragment[],
  preamble: string,
  lineNumbers: "none" | "num" | "line num",
) {
  const tokens: string[] = [];

  if (preamble) {
    tokens.push(preamble);
    tokens.push("\n");
  }

  let lineNumber = 0;
  for (const fragment of transcript) {
    switch (lineNumbers) {
      case "num":
      case "line num":
        tokens.push(lineNumber + " ");
        break;
    }

    tokens.push(fragment.spk === 0 ? "Adviser: " : "Client: ");
    tokens.push(fragment.text);
    tokens.push("\n");

    lineNumber++;
  }

  return tokens.join("");
}

function mergeTranscriptFragments(transcript: TranscriptFragment[], maxPause: number): TranscriptFragment[] {
  let lastSpk: number | undefined;
  let lastStartTime = 0;
  let lastEndTime = 0;

  const merged: TranscriptFragment[] = [];
  const texts: string[] = [];

  for (const fragment of transcript) {
    const { spk, ts, dur, text } = fragment;

    // do some very simplistic utterance merging
    if (spk !== lastSpk || fragment.ts - lastEndTime > maxPause) {
      if (lastSpk !== undefined) {
        merged.push({
          spk: lastSpk,
          ts: lastStartTime,
          dur: lastEndTime - lastStartTime,
          text: texts.join(" "),
        });
      }

      lastSpk = spk;
      lastStartTime = ts;
      texts.length = 0;
    }

    texts.push(text);
    lastEndTime = ts + dur;
  }

  if (lastSpk !== undefined) {
    merged.push({
      spk: lastSpk,
      ts: lastStartTime,
      dur: lastEndTime - lastStartTime,
      text: texts.join(" "),
    });
  }

  return merged;
}

export default CallPage;
