import { useState } from "react";
import { WorkflowItem } from "../../../model/workflow";

const staticItems: WorkflowItem[] = [
  // maybe leave this out for the demo
  // {
  //   id: "missing-info",
  //   title: "Missing info check",
  //   description: "Aveni didn't find everything needed to complete the Fact Find",
  //   assessment: "warning",
  //   checked: false,
  //   checklist: [
  //     {
  //       label: "Postcode",
  //       checked: false,
  //     },
  //     {
  //       label: "Telephone number",
  //       checked: false,
  //     },
  //     {
  //       label: "Risk Assessment Q10",
  //       checked: false,
  //     },
  //   ],
  //   actions: [
  //     {
  //       label: "Review CRM Data",
  //       target: {
  //         type: "crm",
  //       },
  //     },
  //     {
  //       label: "Open Transcript",
  //       target: {
  //         type: "transcript",
  //       },
  //     },
  //   ],
  // },
  {
    id: "qa",
    title: "Instant Compliance Check",
    description: "One issue to look at - no issues that would flag this case for review",
    assessment: "warning",
    checked: false,
    defaultAction: {
      label: "Review Results",
      target: {
        type: "navigate",
        route: "quality",
      },
    },
    detail: `Security:
- Aveni couldn't find the client's postcode`,
    // current UI does this via the default action
    // actions: [
    //   {
    //     label: "Review Results",
    //     target: {
    //       type: "navigate",
    //       route: "quality",
    //     },
    //   },
    // ],
  },
  {
    id: "email",
    title: "Post-meeting email",
    description: "Aveni has drafted a post-meeting email for you to send to the client",
    checked: false,
    defaultAction: {
      label: "Review Email",
      target: {
        type: "navigate",
        route: "email",
      },
    },
  },
  {
    id: "crm",
    title: "Update CRM",
    description: "Aveni has prepared updates for the CRM",
    checked: false,
    defaultAction: {
      label: "Review CRM Data",
      target: {
        type: "navigate",
        route: "crm",
      },
    },
  },
  {
    id: "document",
    title: "Suitability Report",
    description: "Aveni can populate the Suitability Report template with the client's voice",
    checked: false,
    defaultAction: {
      label: "Draft Report",
      target: {
        type: "navigate",
        route: "document",
      },
    },
  },
];

export const useWorkflowItems = () => {
  const [items, setItems] = useState(staticItems);

  const setItemChecked = (id: string, checked: boolean) => {
    const item = items.find((item) => item.id === id);
    if (item) {
      item.checked = checked;
    }
    setItems([...items]);
  };

  const setItemChecklistChecked = (id: string, index: number, checked: boolean) => {
    const item = items.find((item) => item.id === id);
    const checklist = item?.checklist;
    if (checklist) {
      const updatedChecklist = checklist.map((item, i) => (i === index ? { ...item, checked } : item));
      const updatedItemChecked = item.checked !== undefined ? updatedChecklist.every((ci) => ci.checked) : item.checked;

      const updatedItems = items.map((item) =>
        item.id === id ? { ...item, checklist: updatedChecklist, checked: updatedItemChecked } : item,
      );
      setItems(updatedItems);
    }
  };

  return { items, setItemChecked, setItemChecklistChecked };
};
