import { useAuth0 } from "@auth0/auth0-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { ProtectedRoute } from "./components/ProtectedRoute";
import CallPage from "./pages/CallPage";
import CallsPage from "./pages/CallsPage";
import LandingPage from "./pages/LandingPage";
import DemoAgendaPage from "./pages/demo/DemoAgendaPage";
import DemoMeetingPageLayout from "./pages/demo/DemoMeetingPageLayout";
import DemoScratchPage from "./pages/demo/DemoScratchPage";
import { trpc, useTrpcClient } from "./trpc";

import DemoMeetingCRMView from "./pages/demo/DemoMeetingCRMView";
import DemoMeetingDocumentView from "./pages/demo/DemoMeetingDocumentView";
import DemoMeetingEmailView from "./pages/demo/DemoMeetingEmailView";
import DemoMeetingIndexView from "./pages/demo/DemoMeetingIndexView";
import DemoMeetingQAView from "./pages/demo/DemoMeetingQualityView";

const App = () => {
  const [queryClient] = useState(() => new QueryClient());
  const trpcClient = useTrpcClient();

  const { isLoading } = useAuth0();
  if (isLoading) {
    return (
      <div className="flex h-screen justify-center items-center p-8">
        <PulseLoader color="hsl(var(--b3))" size="10px" />
      </div>
    );
  }

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/calls">
              <Route index={true} element={<ProtectedRoute page={CallsPage} />} />
              <Route path=":id" element={<ProtectedRoute page={CallPage} />} />
            </Route>
            <Route path="/demo">
              <Route index={true} element={<ProtectedRoute page={DemoAgendaPage} />} />
              <Route path="meeting/:id" element={<ProtectedRoute page={DemoMeetingPageLayout} />}>
                <Route index={true} element={<DemoMeetingIndexView />} />
                <Route path="crm" element={<DemoMeetingCRMView />} />
                <Route path="email" element={<DemoMeetingEmailView />} />
                <Route path="document" element={<DemoMeetingDocumentView />} />
                <Route path="quality" element={<DemoMeetingQAView />} />
              </Route>

              <Route path="scratch" element={<ProtectedRoute page={DemoScratchPage} />} />
            </Route>
            <Route path="*" element={<p>Path not resolved</p>} />
            {/* <Route path="*" element={<Navigate to="/" />} /> */}
          </Routes>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </trpc.Provider>
  );
};

export default App;
