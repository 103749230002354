export interface MarkRange {
  start: number;
  length: number;
}

const MarkedTranscription: React.FC<{ text: string; marks?: MarkRange[] }> = ({ text, marks }) => {
  if (marks === undefined || marks.length === 0) return <span>{text}</span>;

  type Slice = { start: number; end: number; isMarked: boolean };

  const sortedMarks = [...marks].sort((a, b) => a.start - b.start);
  const slices: Slice[] = [];

  let offset = 0;
  while (offset < text.length) {
    const mark = sortedMarks.shift();
    if (mark) {
      if (mark.start > offset) {
        slices.push({ start: offset, end: mark.start, isMarked: false });
      }
      slices.push({ start: mark.start, end: mark.start + mark.length, isMarked: true });
      offset = mark.start + mark.length;
    } else {
      slices.push({ start: offset, end: text.length, isMarked: false });
      break;
    }
  }

  const markup = slices.map((slice) =>
    slice.isMarked ? (
      <mark key={slice.start}>{text.slice(slice.start, slice.end)}</mark>
    ) : (
      <span key={slice.start}>{text.slice(slice.start, slice.end)}</span>
    ),
  );

  return <span>{markup}</span>;
};

export default MarkedTranscription;
