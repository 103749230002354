import { faAddressBook } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Highlight from "@tiptap/extension-highlight";
import Typography from "@tiptap/extension-typography";
import { EditorContent, JSONContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React, { useContext, useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useNavigate } from "react-router-dom";
import * as vcIcons from "../../assets/vc-icons";
import { AveniEvidenceTrace } from "../../editor/aveni-evidence-trace";
import { WorkflowItem, WorkflowItemAssessment, WorkflowItemTarget } from "../../model/workflow";
import DemoMeetingContainer from "./DemoMeetingContainer";
import DemoMeetingContext from "./DemoMeetingContext";
import TranscriptSidebarContext from "./components/TranscriptSidebarContext";

const DemoMeetingIndexView: React.FC = () => {
  const { workflowItems, setWorkflowItemChecked, setWorkflowItemTaskChecked, briefingNotes, setBriefingNotes } =
    useContext(DemoMeetingContext);

  const { sidebarPinned } = useContext(TranscriptSidebarContext);

  return (
    <DemoMeetingContainer sidebar={<Sidebar />}>
      <div
        className={`max-w-[100vw] overflow-y-auto w-full flex gap-x-4 ${
          sidebarPinned ? "flex-wrap" : "max-xl:flex-wrap"
        }`}
      >
        <div className="lg:min-w-[40ch] max-w-xl basis-auto flex-shrink-0">
          <h2 className="text-lg md:text-xl lg:text-2xl font-light p-4">Workflow </h2>
          <div className="flex flex-col space-y-4 p-4 pt-0">
            {workflowItems.map((item) => (
              <WorkflowItemControl
                key={item.id}
                item={item}
                onSetChecked={(c) => setWorkflowItemChecked(item.id, c)}
                onSetTaskChecked={(i, c) => setWorkflowItemTaskChecked(item.id, i, c)}
                className="p-4 bg-base-100 shadow-md"
              />
            ))}
          </div>
        </div>

        <div className="lg:min-w-[40ch] max-w-screen-xl w-full basis-auto flex-shrink">
          <h2 className="text-lg md:text-xl lg:text-2xl font-light p-4">Briefing Note</h2>
          <BriefingNotesEditor content={briefingNotes} onContentSave={setBriefingNotes} className="p-4 pt-0" />
        </div>
      </div>
    </DemoMeetingContainer>
  );
};

const WorkflowItemControl: React.FC<{
  item: WorkflowItem;
  onSetChecked(checked: boolean): void;
  onSetTaskChecked(index: number, checked: boolean): void;
  className?: string;
}> = (props) => {
  const [expanded, setExpanded] = useState(false);

  const { item, onSetChecked, onSetTaskChecked, className } = props;
  const { assessment, checked, title, description, defaultAction, detail, checklist, actions } = item;

  const { sidebarPinned, setSidebarPinned, setSidebarExpanded } = useContext(TranscriptSidebarContext);
  const navigate = useNavigate();

  const expandable = detail !== undefined || checklist !== undefined;

  const handleTargetClick = (target: WorkflowItemTarget) => {
    switch (target.type) {
      case "navigate":
        navigate(target.route);
        break;
      case "transcript":
        setSidebarPinned(true);
        break;
    }
  };

  return (
    <div className={`flex flex-col ${className ?? ""}`}>
      <div className="flex">
        <div className="w-16">
          <WorkflowItemIcon assessment={assessment} checked={checked} onCheckedChange={(c) => onSetChecked(c)} />
        </div>
        <div className="flex-grow flex flex-col items-start w-full">
          {defaultAction ? (
            <span
              className="pl-2 text-lg font-base link link-hover cursor-pointer xl:tooltip xl:tooltip-right "
              data-tip={defaultAction.label}
              onClick={() => handleTargetClick(defaultAction.target)}
            >
              {title}
            </span>
          ) : (
            <span className="pl-2 text-lg text-left font-medium">{title}</span>
          )}

          {expandable ? (
            <div
              className="flex items-center space-x-2 mt-2 cursor-pointer active:scale-btn-active transition ease-out rounded-btn px-2 bg-opacity-0 hover:bg-opacity-20 bg-base-content"
              onClick={() => setExpanded(!expanded)}
            >
              <span className="">{description}</span>
              <span className="material-symbols-rounded">{expanded ? "expand_less" : "expand_more"}</span>
            </div>
          ) : (
            <span className="px-2 mt-2">{description}</span>
          )}
        </div>
      </div>

      {detail && (
        <div className={`ml-16 pl-2 mt-2 font-light ${!expanded && "hidden"} prose leading-tight`}>
          <ReactMarkdown>{detail}</ReactMarkdown>
        </div>
      )}

      {checklist && (
        <div className={`ml-16 pl-2 mt-2 font-light ${!expanded && "hidden"}`}>
          <ul>
            {checklist.map((checklistItem, index) => (
              <li className="list-item" key={index}>
                <label className="label cursor-pointer w-fit">
                  <input
                    type="checkbox"
                    checked={checklistItem.checked}
                    className="checkbox mr-2"
                    onChange={(e) => onSetTaskChecked(index, e.target.checked)}
                  />
                  {checklistItem.label}
                </label>
              </li>
            ))}
          </ul>
        </div>
      )}

      {actions && (expanded || !expandable) && (
        <div className={`ml-16 pl-2 mt-4 flex space-x-4`}>
          {actions.map((action, i) =>
            action.target.type === "transcript" ? (
              <div key={i}>
                <button
                  className="btn btn-sm btn-outline w-fit self-start hidden lg:inline-flex"
                  disabled={sidebarPinned}
                  onClick={() => setSidebarPinned(true)}
                >
                  {action.label}
                </button>
                <button
                  className="btn btn-sm btn-outline w-fit self-start lg:hidden"
                  onClick={() => setSidebarExpanded(true)}
                >
                  {action.label}
                </button>
              </div>
            ) : (
              <button
                key={i}
                className="btn btn-sm btn-outline w-fit self-start"
                onClick={() => handleTargetClick(action.target)}
              >
                {action.label}
              </button>
            ),
          )}
        </div>
      )}

      {/* copy and paste from above as I'm not sure whether this is what's wanted or not yet */}
      {defaultAction && (
        <div className="mt-4 self-end">
          {defaultAction.target.type === "transcript" ? (
            <div>
              <button
                className={`btn max-md:btn-sm btn-primary ${
                  checked ? "btn-outline" : ""
                } hidden w-48 min-w-fit lg:inline-flex`}
                disabled={sidebarPinned}
                onClick={() => setSidebarPinned(true)}
              >
                {defaultAction.label}
              </button>
              <button
                className={`btn max-md:btn-sm btn-primary ${checked ? "btn-outline" : ""} w-48 min-w-fit lg:hidden`}
                onClick={() => setSidebarExpanded(true)}
              >
                {defaultAction.label}
              </button>
            </div>
          ) : (
            <button
              className={`btn max-md:btn-sm btn-primary ${checked ? "btn-outline" : ""} w-48 min-w-fit`}
              onClick={() => handleTargetClick(defaultAction.target)}
            >
              {defaultAction.label}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

const WorkflowItemIcon: React.FC<{
  assessment?: WorkflowItemAssessment;
  checked?: boolean;
  onCheckedChange: (checked: boolean) => void;
  className?: string;
}> = (props) => {
  const { assessment, checked, onCheckedChange, className } = props;
  const { icon, color } = resolveAssessmentIcon(assessment);

  if (checked === undefined) {
    return (
      <span
        className={`swap-off material-symbols-rounded text-5xl font-extralight cursor-default ${color} ${
          className ?? ""
        }`}
      >
        {icon}
      </span>
    );
  } else {
    return (
      <label
        className="btn btn-circle btn-ghost swap"
        title={checked ? "Click to reset" : "Click to mark as completed"}
      >
        <input type="checkbox" checked={checked} onChange={() => onCheckedChange(!checked)} />
        <span className={`swap-off material-symbols-rounded text-5xl font-extralight ${color}`}>{icon}</span>
        <span className="swap-on material-symbols-rounded material-symbols-filled text-green-500 text-5xl font-extralight">
          check_circle
        </span>
      </label>
    );
  }
};

const Sidebar: React.FC = () => {
  return (
    <div className="p-4 flex flex-col space-y-16">
      <div className="flex flex-col space-y-2">
        <div className="ml-6">Attendees</div>
        <div className="font-light">
          <div className="flex items-center">
            <FontAwesomeIcon icon={faAddressBook} className="w-4 text-lg mr-2" />
            <div>Mr William Smith</div>
          </div>
          <a href="mailto:willsmith@example.net" className="link link-hover ml-6">
            willsmith@example.net
          </a>
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <div className="ml-6">Next on Agenda</div>
        <div className="font-light">
          <div className="flex items-center">
            <img src={vcIcons.msTeams} className="min-w-4 w-4 mr-2" alt="MS Teams icon" />
            <div>Presentation</div>
          </div>
          <div className="ml-6">14:00 &mdash; 16:30 (in 3h 15m)</div>
        </div>
        <div className="font-light">
          <div className="flex items-center">
            <FontAwesomeIcon icon={faAddressBook} className="w-4 text-lg mr-2" />
            <div>Dr Tim Apple</div>
          </div>
          <a href="mailto:ta@example.net" className="link link-hover ml-6">
            ta@example.net
          </a>
        </div>
      </div>
    </div>
  );
};

const BriefingNotesEditor: React.FC<{
  content: JSONContent;
  onContentSave: (content: JSONContent) => void;
  className?: string;
}> = (props) => {
  const { content, onContentSave, className } = props;

  const editor = useEditor({
    extensions: [StarterKit, Highlight, Typography, AveniEvidenceTrace],
    content,
    editorProps: {
      attributes: {
        class: "prose focus:outline-none prose-headings:font-medium max-w-full leading-snug",
      },
    },
  });

  const handleSave = () => {
    if (editor) {
      onContentSave(editor.getJSON());
      // console.log(JSON.stringify(editor.getJSON(), null, 2));
    }
  };

  return (
    <div className={className}>
      <EditorContent editor={editor} onBlur={handleSave} className="bg-base-100 p-4 shadow-md" />
    </div>
  );
};

function resolveAssessmentIcon(assessment?: WorkflowItemAssessment): { icon: string; color: string } {
  switch (assessment) {
    case "query":
      return { icon: "help", color: "material-symbols-filled text-info" };
    case "info":
      return { icon: "info", color: "material-symbols-filled text-info" };
    case "warning":
      return { icon: "warning", color: "material-symbols-filled text-warning" };
    case "error":
      return { icon: "error", color: "material-symbols-filled text-error" };
    case "verified":
      return { icon: "verified", color: "material-symbols-filled text-success" };
    default:
      return { icon: "circle", color: "text-neutral" };
  }
}

export default DemoMeetingIndexView;
