import { ReactNode, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import DemoMeetingContext from "./DemoMeetingContext";
import TranscriptSidebarContext from "./components/TranscriptSidebarContext";

type DemoMeetingContainerProps = {
  navView?: string;
  sidebar?: ReactNode;
  onContentScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
};

const DemoMeetingContainer: React.FC<React.PropsWithChildren<DemoMeetingContainerProps>> = ({ children, ...props }) => {
  const { title, subtitle } = useContext(DemoMeetingContext);
  const { navView, sidebar, onContentScroll } = props;

  return (
    <div className="flex flex-col h-screen max-xl:-ml-64 max-xl:w-[calc(100%+theme(space.64))] transition-all overflow-y-auto bg-base-200 bg-opacity-50">
      <NavBar className="flex-none" title={title} subtitle={subtitle} view={navView} />
      <div className="flex-grow flex overflow-y-auto max-w-full">
        <div className="flex-none w-64 1080p:w-72 1440p:w-80 transition-all overflow-y-auto">{sidebar}</div>
        <div className="flex-grow flex flex-col overflow-y-auto" onScroll={onContentScroll}>
          {children}
        </div>
      </div>
    </div>
  );
};

const NavBar: React.FC<{ title: string; subtitle: string; view?: string; className?: string }> = (props) => {
  const { title, subtitle, view, className } = props;

  const { sidebarPinned, setSidebarPinned, setSidebarExpanded } = useContext(TranscriptSidebarContext);
  const navigate = useNavigate();

  return (
    <div className={`flex items-center bg-base-100 shadow-md z-10 ${className ?? ""} `}>
      <div className="flex-none min-w-64 max-w-80 w-64 1080p:w-72 1440p:w-80 flex px-2 py-1 sm:px-4 sm:py-2">
        <Link className="flex-none flex cursor-pointer" to="/">
          <img src={logo} alt="Aveni Logo" className="max-h-8 block self-center pb-1" />
          <div className="ml-2 text-base-content font-semibold text-2xl self-center opacity-75">assist</div>
        </Link>
      </div>
      <div className="flex-shrink-0 flex-grow flex flex-col text-base-content items-start px-2 py-1 sm:px-4 sm:py-2">
        <h1 className="text-lg sm:text-xl font-semibold">{title}</h1>

        {view ? (
          <span className="text-sm sm:text-base font-light">
            <Link to=".." className="link">
              {subtitle}
            </Link>{" "}
            &gt; {view}
          </span>
        ) : (
          <span className="text-sm sm:text-base font-light">{subtitle}</span>
        )}
      </div>
      <div className="flex-none flex px-2 py-1 sm:px-4 sm:py-2 items-center space-x-0 sm:space-x-2">
        <button
          className="btn btn-ghost btn-circle material-symbols-rounded text-2xl sm:text-3xl leading-none text-base-content font-light text-opacity-75"
          title="Client Search"
        >
          person_search
        </button>
        <button
          className="btn btn-ghost btn-circle material-symbols-rounded text-2xl sm:text-3xl leading-none text-base-content font-light text-opacity-75"
          title="Agenda"
          onClick={() => navigate("../..")}
        >
          calendar_month
        </button>
        <button
          className={`btn btn-ghost btn-circle material-symbols-rounded text-2xl sm:text-3xl leading-none text-base-content font-light text-opacity-75 hidden max-lg:inline-flex`}
          onClick={() => setSidebarExpanded(true)}
          title="Peek Playback and Transcript"
        >
          forum
        </button>
        <button
          className={`btn btn-ghost btn-circle material-symbols-rounded text-2xl sm:text-3xl leading-none text-base-content  font-light text-opacity-75 hidden ${
            !sidebarPinned ? "lg:inline-flex" : ""
          }`}
          title="Pin Playback and Transcript"
          onClick={() => setSidebarPinned(true)}
        >
          forum
        </button>
      </div>
    </div>
  );
};

export default DemoMeetingContainer;
