import { useAuth0 } from "@auth0/auth0-react";
import { faSignIn } from "@fortawesome/free-solid-svg-icons/faSignIn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Navigate } from "react-router-dom";
import aveniLogo from "../assets/images/aveni.png";
import { claimCheck } from "../auth";
import UnauthorizedPage from "./UnauthorizedPage";
const LandingPage: React.FC = () => {
  const { loginWithRedirect, isAuthenticated, user } = useAuth0();

  const onSignIn = () => {
    loginWithRedirect();
  };

  if (isAuthenticated) {
    // extra claim check is not essential as it'll be covered by ProtectedRoute
    // but it seems neater to do it here too so they don't progress past the landing page
    const authorized = claimCheck(user);
    if (authorized) {
      return <Navigate to={"/demo"} replace={true} />;
    } else {
      return <UnauthorizedPage />;
    }
  }

  return (
    <div className="w-full h-screen overflow-hidden">
      <div className="bg-cover bg-bridge w-full h-full min-w-[1280px]">
        <div className="relative bg-cover w-full h-full bg-gradient-to-tl from-transparent to-neutral-200">
          <div className="flex justify-center items-center pt-6 pl-6 min-w-96 min-h-[16rem] w-1/3 h-1/3 text-aveni-blue">
            <div className="flex flex-col space-y-6 w-96 ">
              <h1 className="text-5xl text-aveni-blue drop-shadow ">
                <img src={aveniLogo} alt="Aveni" />
                GPTutils
              </h1>
              <p className="text-2xl text-neutral-800 font-light leading-8">Engineering Tools</p>
              <div>
                <button onClick={onSignIn}>
                  Sign in
                  <FontAwesomeIcon icon={faSignIn} className="ml-2" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
