import TranscriptSidebarContext from "./components/TranscriptSidebarContext";
import DemoMeetingContainer from "./DemoMeetingContainer";

import { PropsWithChildren, useContext } from "react";

const DemoMeetingQualityView: React.FC = () => {
  const { sidebarPinned, setSidebarPinned } = useContext(TranscriptSidebarContext);

  return (
    <DemoMeetingContainer navView="Instant Compliance Check">
      <div className="self-start flex flex-col space-y-4 pt-4 border-1 h-screen flex-grow">
        <h2 className="text-lg md:text-xl lg:text-2xl font-light px-4">Instant Compliance Check</h2>

        <div
          className={`grid gap-8 max-w-screen-xl p-4 pt-0 ${
            sidebarPinned
              ? "sm:grid-cols-1 md:grid-cols-2 1080p:grid-cols-3"
              : "sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3"
          } `}
        >
          <CheckResultGroup label="Client Checks">
            <CheckResult icon="warning" label="Identity verification" alert="alert-warning">
              <div className="flex flex-col">
                <span>Aveni couldn't find the client's postcode</span>
                <button
                  className="btn btn-sm btn-outline w-fit self-start my-2 hidden lg:inline-flex"
                  disabled={sidebarPinned}
                  onClick={() => setSidebarPinned(true)}
                >
                  Check Transcript
                </button>
              </div>
            </CheckResult>
          </CheckResultGroup>

          <CheckResultGroup label="Mandatory Statements">
            <CheckResult label="Restricted advice" />
            <CheckResult label="Data Protection" />
            <CheckResult label="FSCS coverage" />
            <CheckResult label="Fees advised" />
            <CheckResult label="Complaints process" />
            <CheckResult label="Terms and Conditions offered" />
          </CheckResultGroup>

          <CheckResultGroup label="Attitude to Risk">
            <CheckResult label="Questionnaire completed" />
            <CheckResult label="Results explained to client" />
          </CheckResultGroup>

          <CheckResultGroup label="Vulnerability Analysis">
            <CheckResult label="Health" />
            <CheckResult label="Life Events" />
            <CheckResult label="Resilience" />
            <CheckResult label="Capability" />
          </CheckResultGroup>

          <CheckResultGroup label="Consumer Duty">
            <CheckResult label="Price and Value" />
            <CheckResult label="Products and Services" />
            <CheckResult label="Consumer Understanding" />
            <CheckResult label="Consumer Support" />
          </CheckResultGroup>
        </div>
      </div>
    </DemoMeetingContainer>
  );
};

const CheckResultGroup: React.FC<PropsWithChildren<{ label: string }>> = ({ children, ...props }) => {
  const { label } = props;

  return (
    <div className="flex flex-col space-y-4 bg-base-100 shadow-md px-6 py-4">
      <h3 className="md:text-lg lg:text-xl">{label}</h3>
      <div className="flex flex-col space-y-4">{children}</div>
    </div>
  );
};

const CheckResult: React.FC<
  PropsWithChildren<{
    label: string;
    icon?: string;
    alert?: "alert-info" | "alert-warning" | "alert-error" | "alert-success";
  }>
> = ({ children, ...props }) => {
  const { label, icon, alert } = props;

  if (alert) {
    return (
      <div className={`alert ${alert} -ml-2 w-[calc(100%+theme(space.4))] -my-1 px-2 py-1 rounded-btn`}>
        <div className="flex flex-col font-light md:font-normal items-start">
          <div className="flex space-x-2">
            <span className={`material-symbols-rounded flex-none w-6`}>{icon ?? "warning"}</span>
            <span>{label}</span>
          </div>
          {children && <div className="ml-8 font-light">{children}</div>}
        </div>
      </div>
    );
  } else {
    // colour handling here is a bit fragile and not themed - but adequate for now
    return (
      <div className="flex flex-col space-y-2 font-light md:font-normal">
        <div className="flex space-x-2">
          <span className="material-symbols-rounded material-symbol-filled flex-none w-6 material-symbols-filled text-green-500">
            {icon ?? "check_circle"}
          </span>
          <span>{label}</span>
        </div>
        {children && <div className="ml-8 flex flex-col">{children}</div>}
      </div>
    );
  }
};

export default DemoMeetingQualityView;
