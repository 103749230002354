import DemoMeetingContainer from "./DemoMeetingContainer";

import Highlight from "@tiptap/extension-highlight";
import Typography from "@tiptap/extension-typography";
import { Content, EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { AveniEvidenceTrace } from "../../editor/aveni-evidence-trace";
import { DumpJson } from "../../editor/dump-json";
import draftEmail from "./data/email.json";
import DemoMeetingContext from "./DemoMeetingContext";

const EmailView: React.FC = () => {
  const { setWorkflowItemChecked } = useContext(DemoMeetingContext);
  const navigate = useNavigate();

  const [isSimulatingSend, setSimulatingSend] = useState<boolean>(false);

  const [isFocused, setIsFocused] = useState(false);
  const recipient = "willsmith@example.net";
  const subject = "Post-Meeting Catch Up";

  const handleSendClick = () => {
    setSimulatingSend(true);
    setTimeout(() => {
      setSimulatingSend(false);
      setWorkflowItemChecked("email", true);
      navigate("..", {
        relative: "path",
      });
    }, 2000);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <DemoMeetingContainer navView="Post-Meeting Email">
      <div
        className={`max-w-screen-xl self-start flex flex-col space-y-4 pt-4 border-1 h-screen flex-grow overflow-y-auto`}
      >
        <div className="flex items-center px-4 w-full space-x-16">
          <h2 className="text-lg md:text-xl lg:text-2xl font-light flex-grow">Post-Meeting Email</h2>

          <div className="flex space-x-2 items-center">
            <PulseLoader color="hsl(var(--n))" size="10px" className={isSimulatingSend ? "visible" : "invisible"} />
            <button disabled={isSimulatingSend} className="btn btn-primary btn-sm" onClick={handleSendClick}>
              Send
            </button>
          </div>
        </div>

        <div className="p-4 pt-0 overflow-y-auto">
          <div className="bg-base-100 shadow-md flex flex-col space-y-4 p-4">
            <div className="border-b pb-2 focus:outline-none flex">
              {isFocused ? <label className="pr-2 flex-none">To </label> : <></>}
              <input
                onFocus={handleFocus}
                onBlur={handleBlur}
                type="email"
                className="focus:outline-none flex-1 bg-transparent"
                placeholder="Recipient"
                defaultValue={recipient}
              />
            </div>
            <div className="border-b pb-2 focus:outline-none flex">
              <input
                type="text"
                className="focus:outline-none flex-1 bg-transparent"
                placeholder="Subject"
                defaultValue={subject}
              />
            </div>

            <EmailEditor className="flex-grow" content={{ ...draftEmail }} />
          </div>
        </div>
      </div>
    </DemoMeetingContainer>
  );
};

const EmailEditor: React.FC<{
  content: Content;
  className?: string;
}> = (props) => {
  const { content, className } = props;

  const editor = useEditor({
    extensions: [StarterKit, Highlight, Typography, AveniEvidenceTrace, DumpJson],
    content,
    editorProps: {
      attributes: {
        class: "prose focus:outline-none prose-headings:font-medium max-w-full",
      },
    },
  });

  return (
    <div className={className}>
      <EditorContent editor={editor} />
    </div>
  );
};

export default EmailView;
