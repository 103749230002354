import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import React, { MouseEventHandler } from "react";
import { AppTranscriptFragment } from "../../../model/transcript";
import MarkedTranscription from "./MarkedTranscription";
dayjs.extend(duration);
dayjs.extend(utc);

const TranscriptBubble: React.FC<{
  fragment: AppTranscriptFragment;
  speakerRole: "adviser" | "client";
  speakerLabel?: boolean;
  isAccent?: boolean;
  marks?: { start: number; length: number }[];
  onClick?: MouseEventHandler;
}> = ({ fragment, speakerRole, speakerLabel, isAccent, marks, onClick }) => {
  let chatAlign: string;
  let chatBubbleColor: string;

  if (speakerRole === "adviser") {
    chatAlign = "chat-start";
    chatBubbleColor = "chat-bubble-primary";
  } else {
    chatAlign = "chat-end";
    chatBubbleColor = "chat-bubble-secondary";
  }

  const time =
    fragment.ts >= 3600 //
      ? dayjs.utc(fragment.ts * 1000).format("HH:mm:ss")
      : dayjs.utc(fragment.ts * 1000).format("mm:ss");

  return (
    <div className={`chat ${chatAlign}`} onClick={onClick}>
      {speakerLabel && <div className="chat-header px-4">{speakerRole === "adviser" ? "Adviser" : "Client"}</div>}
      <div
        className={
          isAccent
            ? `chat-bubble chat-bubble-accent text-accent-content ${onClick && " cursor-pointer"}`
            : `chat-bubble ${chatBubbleColor} text-base-content bg-opacity-10 ${
                onClick && " hover:bg-opacity-20 cursor-pointer transition-opacity ease-in-out"
              }`
        }
      >
        <MarkedTranscription text={fragment.text} marks={marks} />
      </div>
      <div className="chat-footer opacity-50">
        <time>{time}</time>
      </div>
    </div>
  );
};

export default TranscriptBubble;
