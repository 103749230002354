import { trpc } from "./trpc";

export const useHello = (name: string) => {
  return trpc.hello.useQuery(name);
};

export const useListCalls = () => {
  return trpc.calls.listCalls.useQuery();
};

export const useGetCall = (id: string) => {
  return trpc.calls.getCall.useQuery({ id });
};
