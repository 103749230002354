import { Mark, mergeAttributes } from "@tiptap/react";

export const EVIDENCE_TRACE_MARK = "aveni-evidence-trace";

export interface AveniTraceOptions {
  HTMLAttributes: Record<string, any>;
}

export const AveniEvidenceTrace = Mark.create<AveniTraceOptions>({
  name: EVIDENCE_TRACE_MARK,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      evidence: {
        default: [],
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ["span", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
});
