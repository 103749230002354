import { faAddressBook } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CalendarCRMAccount } from "../../../model/calendar";
import CRMDisplayIdSuffix from "./CRMDisplayIdSuffix";
import OptionalLink from "./OptionalLink";

const CRMAccountLink: React.FC<{ account: CalendarCRMAccount }> = ({ account }) => {
  const { name, title, displayId, url } = account;

  return (
    <div className="flex items-center group">
      <OptionalLink href={url}>
        <FontAwesomeIcon icon={faAddressBook} className="w-6 text-lg mr-2 hidden md:inline  hover:drop-shadow" />
      </OptionalLink>
      <span>
        <OptionalLink href={url}>{title ? `${title} ${name}` : name}</OptionalLink>
        {displayId && <CRMDisplayIdSuffix id={displayId} />}
      </span>
    </div>
  );
};

export default CRMAccountLink;
