import Highlight from "@tiptap/extension-highlight";
import Typography from "@tiptap/extension-typography";
import { BubbleMenu, EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";

import { useRef } from "react";
import { AveniEvidenceTrace } from "../../editor/aveni-evidence-trace";
import { DumpJson } from "../../editor/dump-json";
import findings from "./data/findings.json";

const DemoScratchPage: React.FC = () => {
  const content = { ...findings };

  const scrollContainer = useRef<HTMLDivElement>(null);

  const editor = useEditor({
    extensions: [StarterKit, Highlight, Typography, AveniEvidenceTrace, DumpJson],
    content,
    editorProps: {
      attributes: {
        class: "prose focus:outline-none prose-headings:font-medium",
      },
    },
  });

  // EA 2023-04-24 Diagnositics
  // I think the positioning issues with the popper have always been there - we just mitigated them with the page layout.
  // It does appear to be possible to override the position using getReferenceClientRect but probably not with the other options.
  // The situation might be made worse by a stray relative positioned element in the page layout as that could be causing the popper to be positioned under that element.
  // Seems like the best approach would be to switch to interactive node views instead of using the bubble menu.
  // See https://tiptap.dev/guide/node-views/react

  return (
    <div ref={scrollContainer} className="overflow-auto">
      {editor && (
        <BubbleMenu
          editor={editor}
          tippyOptions={{
            duration: 500,
            placement: "auto",
          }}
        >
          <div className="rounded-box shadow-xl w-[36rem] xl:w-[40rem] border h-96 p-4 bg-red-500 "></div>
        </BubbleMenu>
      )}
      <EditorContent editor={editor} />
    </div>
  );
};

export default DemoScratchPage;
