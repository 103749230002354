import { CalendarEvent } from "../../../model/calendar";
import CRMAccountLink from "./CRMAccountLink";
import CRMMeetingLink from "./CRMMeetingLink";
import CRMWorkflowLink from "./CRMWorkflowLink";

const CRMSection: React.FC<{ event: CalendarEvent; className?: string }> = ({ event, className }) => (
  <section className={`leading-loose ${className ?? ""}`}>
    {event.crmMeeting && <CRMMeetingLink meeting={event.crmMeeting} />}
    {event.crmWorkflow && <CRMWorkflowLink workflow={event.crmWorkflow} />}
    {event.crmAccount && <CRMAccountLink account={event.crmAccount} />}
  </section>
);

export default CRMSection;
