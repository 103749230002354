import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useRef, useState } from "react";
import { QuoteEvidence } from "../../../model/tracing";
import { AppTranscriptFragment } from "../../../model/transcript";
import { MarkRange } from "./MarkedTranscription";
import TranscriptBubble from "./TranscriptBubble";
import { AppTranscriptSearchHit } from "./TranscriptSearch";
dayjs.extend(duration);
dayjs.extend(utc);

const Transcript: React.FC<{
  transcript: AppTranscriptFragment[];
  adviserChannel: number;
  highlightQuote?: QuoteEvidence;
  highlightSearchHit?: AppTranscriptSearchHit;
  audioIndex?: number;
  onSeek?: (ts: number) => void;
  className?: string;
}> = (props) => {
  const { transcript, adviserChannel, highlightQuote, highlightSearchHit, audioIndex, onSeek, className } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const [lastAudioIndex, setLastAudioIndex] = useState<number | undefined>(0);
  const [lastSearchHitIndex, setLastSearchHitIndex] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (audioIndex !== undefined && audioIndex !== lastAudioIndex) {
      const container = containerRef.current;
      const el = container?.children[audioIndex];
      if (el) {
        verticalScrollToElement(container, el as HTMLElement);
        setLastAudioIndex(audioIndex);
      }
    }
  }, [audioIndex, lastAudioIndex]);

  useEffect(() => {
    if (highlightSearchHit !== undefined && highlightSearchHit.index !== lastSearchHitIndex) {
      const container = containerRef.current;
      const el = container?.children[highlightSearchHit.index];
      if (el) {
        verticalScrollToElement(container, el as HTMLElement);
        setLastSearchHitIndex(highlightSearchHit.index);
      }
    }
  }, [highlightSearchHit, lastSearchHitIndex]);

  return (
    <div ref={containerRef} className={className}>
      {transcript.map((f, i) => {
        let isAccent = false;
        let marks: MarkRange[] | undefined;
        if (highlightQuote !== undefined) {
          if (highlightQuote.range.start <= i && i <= highlightQuote.range.end) {
            const quote = highlightQuote.quote;
            const quoteStart = quote ? f.text.indexOf(quote) : -1;
            if (quoteStart !== -1) marks = [{ start: quoteStart, length: quote.length }];
            isAccent = true;
          }
        } else if (highlightSearchHit !== undefined) {
          if (highlightSearchHit.index === i) marks = highlightSearchHit.marks;
        }

        const audioHighlightStyle =
          audioIndex === i //
            ? "border-l-8 border-accent pl-2"
            : "border-l-8 border-transparent pl-2";

        return (
          <div className={audioHighlightStyle} key={i}>
            <TranscriptBubble
              fragment={f}
              speakerRole={f.spk === adviserChannel ? "adviser" : "client"}
              speakerLabel={i < 2}
              isAccent={isAccent}
              marks={marks}
              onClick={onSeek ? () => onSeek(f.ts) : undefined}
            />
          </div>
        );
      })}
    </div>
  );
};

/**
 * Scroll the specified container to the specified element, so that the element is vertically centered in the container
 * without scrolling the top of the element off the top of the container.
 *
 * Use in preference to `element.scrollIntoView()` because that method tends to force the hidden drawer into view.
 *
 * @param container container to scroll
 * @param element element to scroll to
 */
function verticalScrollToElement(container: HTMLElement, element: HTMLElement) {
  // calculate offset to get the element vertically centered in the container,
  // but avoid a negative offset so that the top is always visible
  const middleOffset = Math.max((container.clientHeight - element.clientHeight) / 2, 0);
  container.scrollTo({ top: element.offsetTop - container.offsetTop - middleOffset, behavior: "smooth" });
}

export default Transcript;
