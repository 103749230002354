import { faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CalendarCRMWorkflow } from "../../../model/calendar";
import CRMDisplayIdSuffix from "./CRMDisplayIdSuffix";
import OptionalLink from "./OptionalLink";

const CRMWorkflowLink: React.FC<{ workflow: CalendarCRMWorkflow }> = ({ workflow }) => {
  const { name, stage, displayId, url, value } = workflow;

  return (
    <div className="flex items-center group">
      <OptionalLink href={url}>
        <FontAwesomeIcon icon={faList} className="w-6 text-lg mr-2 hidden md:inline hover:drop-shadow" />
      </OptionalLink>
      <span>
        <a className="link link-hover" href={url}>
          {stage ? `${name} > ${stage}` : name}
        </a>
        {value && <>&nbsp; ({value})</>}
        {displayId && <CRMDisplayIdSuffix id={displayId} />}
      </span>
    </div>
  );
};

export default CRMWorkflowLink;
