import "material-symbols/rounded.css";
import { useState } from "react";

import { JSONContent } from "@tiptap/core";
import { Outlet } from "react-router-dom";
import { QuoteEvidence } from "../../model/tracing.js";
import { AppTranscriptFragment } from "../../model/transcript.js";
import DemoMeetingContext from "./DemoMeetingContext";
import TranscriptSidebar from "./components/TranscriptSidebar";
import TranscriptSidebarContext, { TranscriptSidebarContextProps } from "./components/TranscriptSidebarContext";
import briefingJson from "./data/briefing.json";
import transcriptJson from "./data/transcript.json";
import { useWorkflowItems } from "./data/workflow-items";

const transcript = transcriptJson as AppTranscriptFragment[];

const DEMO_AUDIO = "/audio/f5e35a6a-bbd3-443a-a62e-d4ac27211afa.mp3";
const ADVISER_CHANNEL = 0;

const TITLE = "Fact Find — Will Smith";
const SUBTITLE = "Today 09:00 with Alan Adviser";

const DemoMeetingPageLayout: React.FC = () => {
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const [sidebarPinned, setSidebarPinned] = useState(false);
  const [highlightQuote, setHighlightQuote] = useState<QuoteEvidence | undefined>(undefined);

  const [briefingNotes, setBriefingNotes] = useState<JSONContent>(briefingJson as JSONContent);

  const {
    items: workflowItems,
    setItemChecked: setWorkflowItemChecked,
    setItemChecklistChecked: setWorkflowItemTaskChecked,
  } = useWorkflowItems();

  const contextProps: TranscriptSidebarContextProps = {
    sidebarExpanded,
    setSidebarExpanded,
    sidebarPinned,
    setSidebarPinned,
    highlightQuote,
    setHighlightQuote: (q) => {
      if (q && !sidebarPinned) setSidebarPinned(true);
      setHighlightQuote(q);
    },
  };

  return (
    <DemoMeetingContext.Provider
      value={{
        title: TITLE,
        subtitle: SUBTITLE,
        transcript,
        adviserChannel: ADVISER_CHANNEL,
        briefingNotes,
        setBriefingNotes,
        workflowItems,
        setWorkflowItemChecked,
        setWorkflowItemTaskChecked,
      }}
    >
      <TranscriptSidebarContext.Provider value={contextProps}>
        <div className={`drawer ${sidebarPinned ? "drawer-mobile" : ""} drawer-end `}>
          <input
            id="transcript-drawer"
            type="checkbox"
            className="drawer-toggle"
            checked={sidebarExpanded}
            onChange={() => setSidebarExpanded(!sidebarExpanded)}
          />
          <div className="drawer-content transition-all">
            <Outlet />
          </div>
          <div className="drawer-side">
            <label htmlFor="transcript-drawer" className="drawer-overlay"></label>
            <div className="w-[80vw] lg:min-w-[50ch] lg:max-w-[80ch] lg:w-[25vw] border-l transition-all">
              <TranscriptSidebar
                onClose={() => {
                  setSidebarExpanded(false);
                  setSidebarPinned(false);
                }}
                transcript={transcript}
                audioSource={DEMO_AUDIO}
                highlightQuote={highlightQuote}
                onHighlightQuoteClear={() => setHighlightQuote(undefined)}
              />
            </div>
          </div>
        </div>
      </TranscriptSidebarContext.Provider>
    </DemoMeetingContext.Provider>
  );
};

export default DemoMeetingPageLayout;
