import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { faArrowDown, faClock, faDatabase, faInfoCircle, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useState } from "react";
import "react-calendar/dist/Calendar.css";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import logo from "../../assets/images/logo.png";
import * as vcIcons from "../../assets/vc-icons";
import events from "./data/events";

import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import {
  CalendarEvent,
  CalendarGoogleMeetInvite,
  CalendarInfoItem,
  CalendarRecordingAnalysisState,
  CalendarTeamsInvite,
  CalendarZoomInvite,
} from "../../model/calendar";
import CRMSection from "./components/CRMSection";
import OptionalLink from "./components/OptionalLink";

dayjs.extend(duration);

const now = dayjs("2023-02-14T10:45:12.000Z");

const DemoAgendaPage: React.FC = () => {
  return (
    <div className="flex flex-col h-screen max-lg:-ml-64 max-lg:w-[calc(100%+theme(space.64))] transition-all overflow-y-auto">
      <NavBar className="flex-none" title="Agenda" subtitle="10:45 — Thursday 27 April 2023" />
      <div className="flex-grow flex overflow-y-auto max-w-full bg-base-200 bg-opacity-50">
        <div className="flex-none w-64 1080p:w-72 1440p:w-80 transition-all overflow-y-auto">
          <Calendar className="px-8 py-2" />
        </div>
        <div className=" w-full overflow-y-auto">
          <div className="flex-grow flex flex-col space-y-4 p-4 py-8 w-full max-w-screen-md min-w-fit">
            {events.slice(1, 2).map((event) => (
              <CalendarEventCard key={event.id} event={event} />
            ))}
            <div className="divider">
              <FontAwesomeIcon icon={faClock} />
              10:45 &mdash; 3h 15m
              <FontAwesomeIcon icon={faArrowDown} />
            </div>
            {events.slice(2, 3).map((event) => (
              <CalendarEventCard key={event.id} event={event} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const NavBar: React.FC<{ title: string; subtitle: string; className?: string }> = (props) => {
  const { title, subtitle, className } = props;
  const { logout } = useAuth0();

  const handleProfileClick = () => {
    logout({
      returnTo: window.location.origin,
    });
  };

  return (
    <div className={`flex items-center bg-base-100 shadow-md z-10 ${className ?? ""} `}>
      <div className="flex-none min-w-64 max-w-80 w-64 1080p:w-72 1440p:w-80 flex px-2 py-1 sm:px-4 sm:py-2">
        <Link className="flex-none flex cursor-pointer" to="/">
          <img src={logo} alt="Aveni Logo" className="max-h-8 block self-center pb-1" />
          <div className="ml-2 text-base-content font-semibold text-2xl self-center opacity-75">assist</div>
        </Link>
      </div>
      <div className="flex-shrink-0 flex-grow flex flex-col text-base-content items-start px-2 py-1 sm:px-4 sm:py-2">
        <h1 className="text-lg sm:text-xl font-semibold">{title}</h1>
        <span className="text-sm sm:text-base font-light">{subtitle}</span>
      </div>
      <div className="flex-none flex px-2 py-1 sm:px-4 sm:py-2 items-center space-x-0 sm:space-x-2">
        <button
          className="btn btn-ghost btn-circle material-symbols-rounded text-2xl sm:text-3xl leading-none text-base-content font-light text-opacity-75"
          title="Client Search"
        >
          person_search
        </button>
        <button className="btn btn-ghost text-opacity-75" title="User Account" onClick={handleProfileClick}>
          Alan Adviser
        </button>
      </div>
    </div>
  );
};

export default DemoAgendaPage;

const CalendarEventCard: React.FC<{ event: CalendarEvent }> = ({ event: ce }) => {
  // for the moment, these two are placeholders for behaviour based on recall.ai
  const [isRecordingOn, setIsRecordingOn] = useState(true);
  const canRecord = ce.invite !== undefined && ce.isExternal; //ce.recallAiMeeting !== undefined;

  const start = dayjs(ce.startDateTime);
  const end = dayjs(ce.endDateTime);
  const dur = dayjs.duration(end.diff(start));
  const isPast = dayjs(now.set("hour", 12)).isAfter(end);

  const fDate = now.isSame(start, "day") ? "Today" : start.format("ddd, D MMM");
  const fStartTime = start.format("HH:mm");
  const fEndTime = end.format("HH:mm");
  const fDuration = dur.hours() > 0 ? `${dur.hours()}h ${dur.minutes()}m` : `${dur.minutes()}m`;

  const { invite, recordingAnalysis, recordingAnalysisType } = ce;
  const infoItems = ce.infoItems ?? [];

  return (
    <div className="card card-compact bg-base-100 shadow-md rounded-none">
      <div className="card-body">
        <div className="flex">
          <div className="flex flex-col justify-start flex-grow space-y-4">
            <div>
              <h2 className="card-title">{ce.title}</h2>
              <h3 className="font-light text-lg">
                {fDate} {fStartTime} &mdash; {fEndTime} ({fDuration})
              </h3>
            </div>
            <CRMSection event={ce} />
            {infoItems.map((item, i) => (
              <InfoItem key={i} item={item} />
            ))}
          </div>
          <div className="flex flex-col flex-none justify-start space-y-4 ml-6 mt-1">
            {canRecord && (
              <div className="flex items-center">
                <input
                  id="rec-toggle"
                  type="checkbox"
                  className="toggle toggle-sm"
                  checked={isRecordingOn}
                  disabled={isPast || recordingAnalysis !== undefined}
                  onChange={(e) => setIsRecordingOn(!isRecordingOn)}
                />
                <label htmlFor="rec-toggle" className="hidden xl:inline ml-2 flex-grow align-top">
                  Aveni Recorder
                </label>
              </div>
            )}
            {invite && invite.platform === "teams" && <TeamsInviteWidget invite={invite} />}
            {invite && invite.platform === "meet" && <MeetInviteWidget invite={invite} />}
            {invite && invite.platform === "zoom" && <ZoomInviteWidget invite={invite} />}
            {recordingAnalysis && <RecordingAnalysisStateWidget recordingAnalysis={recordingAnalysis} />}
          </div>
        </div>
        <p></p>
        <div className="card-actions justify-end">
          {!isPast && invite && <button className={`btn`}>Join</button>}
          {recordingAnalysisType && (
            <div>
              <Link
                to={`meeting/${ce.id}`}
                className={
                  recordingAnalysis === undefined || !recordingAnalysis.transcribed
                    ? "btn btn-disabled"
                    : "btn btn-primary"
                }
              >
                {recordingAnalysisType === "transcribe-only" ? "Meeting Playback" : "Meeting Analysis"}
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const TeamsInviteWidget: React.FC<{ invite: CalendarTeamsInvite }> = ({ invite }) => {
  return (
    <div className="flex items-center">
      <a href={invite.url}>
        <img src={vcIcons.msTeams} className="min-w-6 w-6 mx-1 hover:drop-shadow" alt="MS Teams icon" />
      </a>
      <a href={invite.url} className="hidden xl:inline ml-2 flex-grow link link-hover">
        Teams Meeting
      </a>
    </div>
  );
};

const MeetInviteWidget: React.FC<{ invite: CalendarGoogleMeetInvite }> = ({ invite }) => {
  return (
    <div className="flex items-center">
      <a href={invite.url}>
        <img src={vcIcons.googleMeet} className="min-w-6 w-6 mx-1 hover:drop-shadow" alt="Google Meet" />
      </a>
      <a href={invite.url} className="hidden xl:inline ml-2 flex-grow link link-hover">
        Google Meeting
      </a>
    </div>
  );
};

const ZoomInviteWidget: React.FC<{ invite: CalendarZoomInvite }> = ({ invite }) => {
  return (
    <div className="flex items-center">
      <a href={invite.url}>
        <img src={vcIcons.zoom} className="min-w-6 w-6 mx-1 hover:drop-shadow" alt="Zoom" />
      </a>
      <a href={invite.url} className="hidden xl:inline ml-2 flex-grow link link-hover">
        Zoom Meeting
      </a>
    </div>
  );
};

const RecordingAnalysisStateWidget: React.FC<{ recordingAnalysis: CalendarRecordingAnalysisState }> = ({
  recordingAnalysis,
}) => {
  const isComplete = recordingAnalysis.percentComplete >= 100;
  const { status, percentComplete } = recordingAnalysis;

  if (isComplete) {
    return (
      <div className="flex items-center">
        <img src={logo} className="min-w-6 w-6 mx-1" alt="Aveni Icon" />
        <span className="hidden xl:inline ml-2 flex-grow">{recordingAnalysis.status}</span>
      </div>
    );
  } else {
    return (
      <>
        <div className="flex items-center">
          <img src={logo} className="min-w-6 w-6 mx-1 animate-pulse" alt="Aveni Icon" />
          <div className="hidden xl:flex flex-col flex-grow ml-2 space-y-1 opacity-75">
            <progress className="progress w-24" value={percentComplete} max="100" />
            <span className="text-xs">{status}</span>
          </div>
        </div>
      </>
    );
  }
};

const InfoItem: React.FC<{ item: CalendarInfoItem }> = ({ item }) => {
  const { source, title, content, url } = item;

  return (
    <div>
      {source === "aveni" ? (
        <img src={logo} className="w-6 align-top mr-2 hidden md:inline" alt="Aveni Icon" />
      ) : (
        <FontAwesomeIcon icon={infoIcon(source)} className="w-6 text-lg align-top mr-2 hidden md:inline" />
      )}

      <div className="inline-block">
        {title && (
          <OptionalLink href={url}>
            <p>{title}</p>
          </OptionalLink>
        )}
        {content && <ReactMarkdown className="font-light italic prose max-w-prose text-sm">{content}</ReactMarkdown>}
      </div>
    </div>
  );
};

const Calendar: React.FC<{ className?: string }> = (props) => {
  const { className } = props;
  return (
    <div className={className}>
      <div className="flex flex-col w-fit">
        <CalendarDay day="Mon" dayOfMonth={24} />
        <CalendarDay day="Tue" dayOfMonth={25} />
        <CalendarDay day="Wed" dayOfMonth={26} />
        <CalendarDay day="Thu" dayOfMonth={27} active={true} />
        <CalendarDay day="Fri" dayOfMonth={28} />
        <CalendarDay day="Sat" dayOfMonth={29} />
        <CalendarDay day="Sun" dayOfMonth={30} />
      </div>
    </div>
  );
};

const CalendarDay: React.FC<{ day: string; dayOfMonth: number; active?: boolean }> = (props) => {
  const { day, dayOfMonth, active } = props;

  if (active) {
    return (
      <div className="flex group bg-accent shadow-lg light-shadow rounded-lg m-1 cursor-pointer justify-center w-16">
        <div className="p-3">
          <div className="text-center">
            <p className="text-accent-content text-sm">{day}</p>
            <p className="text-accent-content mt-2 font-bold">{dayOfMonth}</p>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex group hover:bg-opacity-50 hover:bg-accent hover:shadow-lg hover:light-shadow rounded-lg m-1 cursor-pointer justify-center w-16 transition-all duration-300">
        <div className="p-3 text-center">
          <p className="group-hover:text-accent-content text-sm transition-all duration-300">{day}</p>
          <p className="group-hover:text-accent-content group-hover:font-bold mt-2 transition-all duration-300">
            {dayOfMonth}
          </p>
        </div>
      </div>
    );
  }
};

function infoIcon(source: string): IconDefinition {
  switch (source) {
    case "crm":
      return faDatabase;
    case "calendar":
      return faCalendar;
    default:
      return faInfoCircle;
  }
}
