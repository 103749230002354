import React from "react";
import { QuoteEvidence } from "../../../model/tracing.js";

export type TranscriptSidebarContextProps = {
  sidebarExpanded: boolean;
  setSidebarExpanded: (expanded: boolean) => void;
  sidebarPinned: boolean;
  setSidebarPinned: (pinned: boolean) => void;
  highlightQuote: QuoteEvidence | undefined;
  setHighlightQuote: (quote: QuoteEvidence | undefined) => void;
};

const TranscriptSidebarContext = React.createContext<TranscriptSidebarContextProps>({
  sidebarExpanded: false,
  setSidebarExpanded: () => {},
  sidebarPinned: false,
  setSidebarPinned: () => {},
  highlightQuote: undefined,
  setHighlightQuote: () => {},
});

export default TranscriptSidebarContext;
