import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CalendarCRMMeeting } from "../../../model/calendar";
import CRMDisplayIdSuffix from "./CRMDisplayIdSuffix";
import OptionalLink from "./OptionalLink";

const CRMMeetingLink: React.FC<{ meeting: CalendarCRMMeeting }> = ({ meeting }) => {
  const { subject, displayId, url, type } = meeting;

  return (
    <div className="flex items-center group">
      <OptionalLink href={url}>
        <FontAwesomeIcon icon={faCalendar} className="w-6 text-lg mr-2 hidden md:inline hover:drop-shadow" />
      </OptionalLink>
      <span className="group">
        <a className="link link-hover" href={url}>
          {subject}
        </a>
        {type && <>&nbsp; ({type})</>}
        {displayId && <CRMDisplayIdSuffix id={displayId} />}
      </span>
    </div>
  );
};

export default CRMMeetingLink;
