import Highlight from "@tiptap/extension-highlight";
import Typography from "@tiptap/extension-typography";
import { Content, EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { AveniEvidenceTrace } from "../../editor/aveni-evidence-trace";
import { DumpJson } from "../../editor/dump-json";
import { QuoteEvidence } from "../../model/tracing";
import EvidenceEditorBubbleMenu from "./components/EvidenceEditorBubbleMenu";
import TranscriptSidebarContext from "./components/TranscriptSidebarContext";
import findings from "./data/findings.json";
import DemoMeetingContainer from "./DemoMeetingContainer";
import DemoMeetingContext from "./DemoMeetingContext";

const DemoMeetingDocumentView: React.FC = () => {
  // obviously this would need to be parameterised by content -
  // we're just importing a static set of findings for a fixed purpose right now

  const { setWorkflowItemChecked } = useContext(DemoMeetingContext);
  const navigate = useNavigate();

  const [isNeverEdited, setNeverEdited] = useState<boolean>(true);
  const [isDirtyEditor, setDirtyEditor] = useState<boolean>(false);

  const { setHighlightQuote } = useContext(TranscriptSidebarContext);
  const [isSimulatingDocCreation, setSimulatingDocCreation] = useState<boolean>(false);

  const handleCreateDocumentClick = () => {
    setSimulatingDocCreation(true);
    setTimeout(() => {
      window.open(
        "https://docs.google.com/document/d/13a-dQt4nCSKbmDfIEFvWesgA2jm2aKx08tOZ33nstLM/edit?usp=sharing",
        "_blank",
      );
      setSimulatingDocCreation(false);
      setWorkflowItemChecked("document", true);
      navigate("..", {
        relative: "path",
      });
    }, 2000);
  };

  const onSave = () => {
    // fake!
    setDirtyEditor(false);
  };

  return (
    <DemoMeetingContainer navView="Suitability Report">
      <div
        className={`max-w-screen-xl pt-4 self-start flex flex-col space-y-4 border-1 h-screen overflow-y-auto flex-grow`}
      >
        <div className="flex items-center px-2 sm:px-4 w-full space-x-16">
          <h2 className="text-lg md:text-xl lg:text-2xl font-light flex-grow">Suitability Report</h2>
          <div className="flex space-x-2 items-center">
            <PulseLoader
              color="hsl(var(--n))"
              size="10px"
              className={isSimulatingDocCreation ? "visible" : "invisible"}
            />
            <button
              disabled={!isDirtyEditor}
              className={`btn btn-secondary btn-sm ${isNeverEdited ? "hidden" : "inline-flex"}`}
              onClick={onSave}
            >
              Save
            </button>
            <button
              disabled={isSimulatingDocCreation}
              className="btn btn-primary btn-sm"
              onClick={handleCreateDocumentClick}
            >
              <span className="hidden md:inline">Create Document from Template</span>
              <span className="md:hidden">Create</span>
            </button>
          </div>
        </div>
        <div className="p-4 pt-0 overflow-y-auto">
          <DocumentEditor
            className="flex-grow p-4 border shadow-md bg-base-100 h-fit w-full"
            content={{ ...findings }}
            onQuoteSelectionChange={setHighlightQuote}
            onUpdate={() => {
              setNeverEdited(false);
              setDirtyEditor(true);
            }}
          />
        </div>
      </div>
    </DemoMeetingContainer>
  );
};

const DocumentEditor: React.FC<{
  content: Content;
  onQuoteSelectionChange?: (quote: QuoteEvidence | undefined) => void;
  onUpdate?: () => void;
  className?: string;
}> = (props) => {
  const { transcript, adviserChannel } = useContext(DemoMeetingContext);

  const { content, onQuoteSelectionChange, onUpdate, className } = props;

  const editor = useEditor({
    extensions: [StarterKit, Highlight, Typography, AveniEvidenceTrace, DumpJson],
    content,
    editorProps: {
      attributes: {
        class: "prose focus:outline-none prose-headings:font-medium max-w-full",
      },
    },
    onUpdate,
  });

  return (
    <div className={className}>
      {editor && (
        <EvidenceEditorBubbleMenu
          className="hidden md:block"
          editor={editor}
          transcript={transcript}
          adviserChannel={adviserChannel}
          onQuoteEvidenceSelected={onQuoteSelectionChange}
        />
      )}
      <EditorContent editor={editor} />
    </div>
  );
};

export default DemoMeetingDocumentView;
