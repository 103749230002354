const OptionalLink: React.FC<{ href?: string; children: React.ReactNode }> = ({ href, children }) => {
  return href ? (
    <a href={href} className="link link-hover">
      {children}
    </a>
  ) : (
    <span>{children}</span>
  );
};

export default OptionalLink;
