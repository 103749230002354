import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const ErrorAlert: React.FC<{ message: string }> = (props) => (
  <div className="alert alert-error shadow-lg mb-6">
    <div>
      <FontAwesomeIcon icon={faExclamationTriangle} />
      <span>{props.message}</span>
    </div>
  </div>
);
