import { useState } from "react";

export type TranscriptPrefs = JsonTranscriptPrefs | TextTranscriptPrefs;
export interface JsonTranscriptPrefs {
  type: "json";
  maxPause?: number;
}
export interface TextTranscriptPrefs {
  type: "text";
  lineNumbers?: "none" | "num" | "line num";
  preamble?: string;
  maxPause?: number;
}

export const useTranscriptPrefs = (): [TranscriptPrefs, (prefs: TranscriptPrefs) => void] => {
  const [prefs, setPrefs] = useState<TranscriptPrefs>(loadTranscriptPrefs());

  return [
    prefs,
    (prefs: TranscriptPrefs) => {
      setPrefs(prefs);
      saveTranscriptPrefs(prefs);
    },
  ];
};

function loadTranscriptPrefs(): TranscriptPrefs {
  const prefs = localStorage.getItem("transcript-prefs-v2");
  return prefs ? JSON.parse(prefs) : makeDefaultPrefs();
}

function saveTranscriptPrefs(prefs: TranscriptPrefs) {
  localStorage.setItem("transcript-prefs-v2", JSON.stringify(prefs));
}

function makeDefaultPrefs(): TranscriptPrefs {
  return {
    type: "text",
    lineNumbers: "num",
    preamble: "Transcript:",
    maxPause: 1,
  };
}
